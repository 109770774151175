<template>
  <div class="Visualisation">
    <div id="chartdiv" ref="chartdiv" class="Visualisation__chart"></div>
  </div>
</template>

<script>

// import * as d3 from "d3"

export default {

  name: 'Visualisation',
  data: ()=>({

    width: window.innerWidth,
    height: window.innerHeight*.7,
    nodes: {},
    prefixes: [],
    titles: [],
    graph: {
      nodes: [
        { index: "center", x: null, y: null, title: 'Base de données' },
      ],
      links: []
    },
    paddingX: 0,
    paddingY: 0,
    padding: 200,
    simulation: null,
    currentMove: null
  }),

  created() {

    console.log('created !');

    let count = 1
    let arr = []
    _.each(this.parcoursGroupByCategories, (ps, i)=>{ 
      arr.push(i) 
    })
    const parcs = _.sampleSize(arr, 4)
    let obj = {}
    _.each(parcs, (title)=>{ 
      obj[title] = this.parcoursGroupByCategories[title] 
    })

    _.each(obj, (ps,i) => {
      this.graph.nodes.push({
        index: 'node_' + count,
        x: null,
        y: null,
        title: i,
        loaded: true
      })
      _.each(ps, (p,i) => {
        this.graph.nodes.push({
          index: 'sub_' + count + '_' + (i+1),
          x: null,
          y: null,
          type: p.type,
          slug: p.id,
          title: p.title,
          url: p.url,
          loaded: true
        })
        if(p.visualisation_items && p.visualisation_items != '') {
          const items = p.visualisation_items.split(',')
          _.each(items, (item, itemIndex) => {
            const id = parseInt(item)
              const nodeAsset = {
                index: 'ter_' + count + '_' + (i+1) + '_' + (itemIndex+1),
                x: null,
                y: null,
                type: 'item',
                loaded: false,
                title: ''
              }
              this.graph.nodes.push(nodeAsset)
              this.getAsset(id).then(asset => {
                if(!asset) return
                nodeAsset.title = asset.title
                nodeAsset.loaded = true
              })
          })

        }
      })
      count++
    })

    if(window.innerHeight <= 960) {
      this.paddingX = 225
      this.paddingY = 75
    }
    else if(this.$root.below('tablet')) {
      this.paddingX = 75
      this.paddingY = 75
    }
    else {
      this.paddingX = 225
      this.paddingY = 225
    }

    this.createLinks() 
  },


  mounted() {

    TweenMax.delayedCall(1.5, ()=>{

      const chart = am4core.create("chartdiv", am4plugins_forceDirected.ForceDirectedTree)
      const series = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())
            series.paddingTop = 100
            series.paddingBottom = 100
            series.paddingRight = 100
            series.paddingLeft = 100

      am4core.options.autoSetClassName = true

      this.$external_data = [{ 
        id: "center", 
        name: "", 
        value : 1, 
        fixed: true, 
        color: "#ffffff", 
        children: [] 
      }]

      _.each(this.graph.nodes, (node, pos)=>{
        const split = node.index.split('_')
        switch(split[0]){
          case 'node':
            this.setData(pos, 3, '#183252', node, this.$external_data[0]['children'])
            break

        case 'sub':
          const sub = split[1] - 1
          const color = (node.type == 'item') ? '#8db5df' : '#1f95ff'
          this.setData(pos, 1, color, node, this.$external_data[0]['children'][sub]['children'])
          break

        case 'ter':
          const sub_1 = split[1] - 1
          const ter = split[2] - 1
          this.setData(pos, 1, '#8db5df', node, this.$external_data[0]['children'][sub_1]['children'][ter]['children'])
          break
        }
      })

      series.dataFields.value = "value"
      series.dataFields.name = "name"
      series.dataFields.children = "children"
      series.dataFields.color = "color"
      series.dataFields.fixed = "fixed"

      series.nodes.template.tooltipText = "{name}"
      series.nodes.template.fillOpacity = 1
      series.nodes.template.togglable = false

      series.nodes.template.outerCircle.fill = "red"
      series.nodes.template.outerCircle.strokeOpacity = 0
      series.nodes.template.outerCircle.fillOpacity = 0
     
      series.nodes.template.circle.stroke = "#8db5df"
      series.nodes.template.circle.strokeWidth = 2
      series.nodes.template.circle.strokeOpacity = 0

      series.links.template.strokeWidth = 2
      series.links.template.distance = 2
      series.links.template.disabled = false
   
      // Add labels
      series.nodes.template.label.text = "{sub}"

      //series.fontSize = 10;
      series.minRadius = 1;
      series.maxRadius = 50;
      series.centerStrength = .35;
      series.fontSize = 13
      // series.maxLevels = 1
      series.maxRadius = am4core.percent(6.5)
      // series.manyBodyStrength = -16
      series.nodes.template.label.hideOversized = false
      series.nodes.template.events.on("hit", this.onHit, this)
   
      this.$nextTick(()=>{
        series.data = this.$external_data
      })

    })

  },

  computed: {

    itemsAlone() {
      const items = this.$store.getters['data/pages'].home.items_visualisation
      return _.map(items, v => ({ ...v[this.lang] }))
    },

    parcoursGroupByCategories() {
      const parcours = _.filter(this.$store.getters['data/parcours'], p => p.in_visualisation)
      const categories = this.$store.getters['data/categories']
      const group = {}
      _.each(categories, categorie => {
        const parcour = _.filter(parcours, p => p.categories.indexOf(categorie) > -1)
        if(parcour.length === 0) return
        _.each(parcour, p => p['type'] = 'parcours')
        group[categorie] = parcour
        _.each(group[categorie], c => c.in_category = categorie)
      })
      _.each(categories, categorie => {
        const items = _.filter(this.itemsAlone, ia => ia.categorie == categorie)
        if(items.length === 0) return
        _.each(items, p => p['type'] = 'item')
        if(group[categorie])
          group[categorie] = [...group[categorie], ...items]
      })
      const ordered = _.orderBy(group, g => g.length, ['desc'])
      const cats = {}
      _.each(ordered, o => {
        cats[o[0].in_category] = o
      })
      return cats
    },
  },


  methods: {



    setData(pos, value, color, node, target) {

      const sub = this.getTitle(node.title)

      target.push({
        name: node.title,
        sub: sub,
        color: color,
        value: value, 
        children: [],
        pos: pos,
        ...node
      })
    },

    onHit(evt) {
      if(evt.target.dataItem._dataContext.pos) {
        const node = {
          title: evt.target.dataItem._dataContext.title,
          url: evt.target.dataItem._dataContext.url,
          slug: evt.target.dataItem._dataContext.slug,
          type: evt.target.dataItem._dataContext.type
        }
        const pos = evt.target.dataItem._dataContext.pos
        this.onClick(node, pos)
      }
    },

    createLinks() {
      _.each(this.graph.nodes, (node, pos)=>{
        const split = node.index.split('_')
        switch(split[0]){
          case 'node':
            this.nodes[node.index] = pos
            this.prefixes[pos] = 'node'
            this.graph.links.push({ source: 0, target: pos })
            break
          case 'sub':
            this.nodes[node.index] = pos
            this.prefixes[pos] = 'sub'
            this.graph.links.push({ source: this.nodes['node_'+ split[1]], target: pos })
            break
          case 'ter':
            this.nodes[node.index] = pos
            this.prefixes[pos] = 'ter'
            this.graph.links.push({ source: this.nodes['sub_'+ split[1] +'_'+ split[2]] +'', target: pos })
            break
          default:
            this.nodes[node.index] = pos
            this.prefixes[pos] = 'center'
        }
      })
    },

    getTitle(title) {
      let split = title.split(' ')

      // console.log(split);


      if(split.length > 2)
        return split[0] +' '+ split[1] +" ..."
      else 
         return split[0]
    },

    // FORCES

    onClick(node, pos) {

      // console.log(node);
      // console.log(this.prefixes[pos]);
      // console.log(node.type);

      if(this.prefixes[pos] == 'node')
        this.$router.replace({ params: { categorie: node.title }})

      if(node.type == 'item')
        window.open(node.url, '_blank')

      if(node.type == 'parcours')
        this.$router.push({ params: { parcours: node.slug } })
    }
  },
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Visualisation
  position absolute
  // top 100px
  left 0vw
  width 100vw
  height 100vh
  // height calc(100vh - 200px)

  +below(tablet)
    top 60px
    height 70vh

  svg
    width 100%
    height 100%

  .pointer
    cursor pointer

  &__chart 
    width 100%
    height 100%

  .amcharts-Sprite-group .amcharts-Circle-group
    cursor pointer
    // fill red !important

  .amcharts-ForceDirectedLink
    stroke #1f95ff !important
    stroke-opacity 1
    stroke-width 2

  .amcharts-AmChartsLogo-group 
    display none !important

  // .amcharts-ForceDirectedLink-group
  // .amcharts-Circle
    // fill #8db5df
    // stroke #1f95ff

</style>


