<template> 
  <div class="DateComp" :style="{ zIndex: zindex }">  
    <div class="DateComp__txt">AAAA-MM-JJ</div>
    <input ref="name" :placeHolder="placeholder" class="DateComp__input" type="text" name="input" v-model="name">
  </div>
</template>

<script>

export default {
  name: 'DateComp',
  props: ['obj', 'zindex'],
  data: () => ({
    name: '',
    placeholder: ''
  }),
  created() {
    this.placeholder = 'Inscrire une date'
  },
}

</script>

<style lang='stylus'>
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.DateComp
  display flex
  flex-direction row
  position relative
  border-left 1px solid grey
  z-index 900
  padding-top 6px
  padding-left 12px

  &__txt
    width 180px
    line-height 26px

  &__input
    font-family 'IBM Plex Sans', sans-serif 
    font-size 16px
    border none
    color black
    width 100%
    background none

  &__input::placeholder
    color grey
    font-size 12px
    
</style>



