<template>
<div id="App" :class="{'App-one': getClass() }">
  <Navigation/>
  <div class="Container">
    <transition
      @enter="enter" @after-enter="afterEnter"
      @leave="leave" @after-leave="afterLeave"
      @before-enter="beforeEnter"
      :css="false" appear :mode="mode">
      <router-view ref="page" v-if="loaded" :key="lang" />
    </transition>
    <!-- <Parcours v-if="$route.params.parcours" :key="$route.params.parcours"/> -->
    <Front ref="front"/>
    <Fullscreen/>
    <Search/>
    <Landscape v-if="!$root.is_desktop || $root.isTablet || $root.isPhone"/>
  </div>

  <Loader v-if="this.used == false" />
</div>
</template>

<script>
import Loader from '@/components/loader/Loader'
import Navigation from '@/components/app/Navigation'
import Landscape from '@/components/landscape/Landscape'
import Search from '@/components/search/Search'
import Front from '@/components/app/Front'
import Fullscreen from '@/components/app/Fullscreen'
import Transition from '@/core/mixins/Transition'
import DATA from './data'
import Parcours from '@/components/parcours/Parcours'

export default {
  name: 'App',
  mixins: [ Transition ],
  components: { Loader, Navigation, Front, Search, Fullscreen, Parcours, Landscape },
  watch: {
    lang() {
      // window.location.reload()
    },
    loaded(nw){
      if(nw)
        TweenMax.delayedCall(2, ()=>this.used = true )
    }
  },
  computed: {
    loaded() {
      return this.$store.getters['loader/loaded']
    }
  },
  data:()=>({
    used: false
  }),
  created: async function () {
    // this.$store.commit('data/RESET')
    // this.parserMedias(this.parcours)
  },
  methods: {

    getClass() {
      return false
      // return this.$root.isPhone || this.$root.isTablet || navigator.maxTouchPoints == 5
    },

  },

}
</script>

<style lang="stylus">
@import '~reset-css/reset.css'
@import '~@/config/Settings'
@import '~@/commons/fonts/Fonts'
@import '~@/commons/stylus/Global'
@import '~@/commons/stylus/Page'
@import '~@/commons/stylus/Vlazy'
@import '~@/commons/stylus/Flex'

.App-one
  height 100vh
  overflow hidden
  position absolute
  top 0px
  width 100vw

</style>

