<template> 
  <div class="LabelComp" :style="{ zIndex: zindex }">  
    <Dropdown ref="dropdown" :items="array" idx="search" clickOnItem="clickOnItem" :scroll="$scroll" v-if="ready"/> 
  </div>
</template>

<script>

import Dropdown from '@/components/app/Dropdown'

export default {

  name: 'LabelComp',
  components: { Dropdown },
  props: ['obj', 'zindex'],

  data: () => ({
    ready: false,
    array: [],
  }),

  created() {
    this.$scroll = document.querySelector('.Search')
  },

  mounted() {

    _.each(this.obj, (n, i)=>{
      const o = { id: n, title: n, order: i }
      this.array.push(o)
    })

    this.ready = true
  },

  methods: {

    clickOnItem(id) {
      console.log(id);
    },

  },

  watch: {
  },

  computed: {
  }

}

</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.LabelComp
  position relative
  border-left 1px solid grey
  z-index 900
  padding-top 6px

  .Dropdown
    width 240px


</style>



