<template>
  <div class="BlockComp">
    <div class="BlockComp__title" @click="onClick()">{{ obj.title }}</div>
    <div :key="i" v-for="(obj, i) in obj.blocks">

      <div class="BlockComp__subtitle">
        <div class="BlockComp__icon" :style="{ backgroundImage: 'url('+ image +')' }"/>
        <div>{{ obj.title }}</div>
      </div>
      <RowComp :key="i" v-for="(content, i) in obj.content" :obj="content" :pos="i"/>

  </div>
  </div>
</template>

<script>

import RowComp from './RowComp'

export default {

  name: 'BlockComp',
  components: { RowComp },
  props: ['obj', 'pos'],

  data: () => ({
    zindex: 1000,
    context: 'open',
    image: null
  }),

  created() {
    this.zindex -= this.pos*10
  },

  mounted() {

    if(this.pos > 0) this.close(0)
    this.image = require('@/commons/img/arrow.png')
  },

  methods: {

    onClick() {
      if(this.context === 'open') this.close()
      else this.open()
    },

    open(_speed) {
      const speed = (_speed == undefined) ? .25 : _speed
      this.context = 'open'
      TweenMax.to(this.$el, .25, { className: '-=is-active', onComplete: ()=>{
        this.hiddenContainer(false)
      } })
    },

    close(_speed) {
      const speed = (_speed == undefined) ? .25 : _speed
      this.context = 'close'
      this.hiddenContainer(true)
      TweenMax.to(this.$el, speed, { className: '+=is-active' })
    },

    hiddenContainer(b) {
      if(b) TweenMax.set(this.$el, { overflow: 'hidden' })
      else TweenMax.set(this.$el, { overflow: 'initial' })
    },

  },

}

</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.BlockComp
  display flex
  flex-direction column
  padding-bottom 16px

  &.is-active
      height 32px

  &__title
    min-height 32px
    padding 10px 20px
    background-color #94d4ff
    color white
    display flex
    align-items center
    cursor pointer

  &__subtitle
    min-height 48px
    padding 10px 20px 10px 32px
    color black
    display flex
    align-items center

    font-family "Libre Baskerville"
    font-weight 700
    font-style normal
    font-size 16px

  &__icon
    width 23px
    height 11px
    margin-right 16px


</style>



