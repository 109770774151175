<template>
  <div class="HeaderHome Flex__column-center">
    <div ref="container" class="Page__header-container HeaderHome__container">
      <div class="Page__header-block left Page__title" v-html="trans('title')"/>
      <div class="Page__header-block Flex__row-center">
        <div class="Page__header-sub relative" v-if="!$root.below('tablet')"></div>
      </div>
      <div ref="close" class="HeaderHome__close-container" @click="onClick()" v-if="$route.params.categorie != trans('Global.categorie-all')">
        <div class="HeaderHome__text">{{ trans('filter') }} {{ $route.params.categorie }}</div>
        <div class="HeaderHome__close">
          <svg viewBox="0 0 14.7 14.71">
            <line class="cls-1" x1="0.35" y1="0.35" x2="14.34" y2="14.35"/>
            <line class="cls-1" x1="0.35" y1="14.35" x2="14.34" y2="0.35"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Settings from '@/config/Settings'
import Dropdown from '@/components/app/Dropdown'

export default {

  name: 'HeaderHome',
  components: { Dropdown },
  data: ()=>({
    image: null
  }),
  translations: {
    en: {
      title: "Discover the<br> encyclopedia's parcours",
      filter: "Filter by "
    },
    fr: {
      title: "Découvrez les parcours</br>de l’encyclopédie",
      filter: "Filtré par "
    }
  },
  created() {
    this.$categories = [
      { id: 'categorie-all', title: 'Toutes categories' },
      { id: 'categorie-a', title: 'Categorie A' },
      { id: 'categorie-b', title: 'Lorem ipsum</br>Veni vici B' },
      { id: 'categorie-c', title: 'Categorie C' },
      { id: 'categorie-d', title: 'Categorie D' },
      { id: 'categorie-e', title: 'Categorie E' },
      { id: 'categorie-f', title: 'Categorie F' },
    ]
  },
  methods: {
    onClick() {
      this.$router.push({ params: { categorie: this.trans('Global.categorie-all') }})
    },
    selectByCategorie(id) {
      this.$router.push({ params: { categorie: id }})
    },
  },
  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
  }

}
</script>

<style lang="stylus">

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.HeaderHome
  width 100%
  height 100%
  position relative

  &__container
    display flex
    justify-content center
    align-items center

    +below(tablet)
      align-items flex-start
      justify-content flex-start
      flex-direction column

  &__close-container
    position absolute
    cursor pointer
    display flex
    felx-direction row
    right 0

  &__text
    padding-right 12px
    line-height 12px

  &__close
    width 14px
    top 20px
    right 20px

  .cls-1
    fill none
    stroke #afafaf
    stroke-miterlimit 10

  .relative
    position relative

  .Page__header-block.left
    padding 24px 64px 0 0

    +below(tablet)
      padding 0

    +below(portrait)
      padding 24px 64px 0 0  
    
</style>


