<template> 
  <div class="ListComp" :class="'is-'+ css_pos" :style="{ zIndex: zindex }">  
    <div class="ListComp__container">
      <div class="ListComp__title">{{ obj.title }}</div>
      <div class="ListComp__list" :class="'is-'+ css_size">
        <template v-for="(n, i) in array">  
          <div class="ListComp__checkbox">  
            <div>
              <input type="checkbox" :id="$parent.$parent.id +'_'+ obj.title +'_'+ pos +'_'+ i" :name="$parent.$parent.id +'_'+ obj.title +'_'+ pos +'_'+ i" :value="n.title" v-if="n.type=='checkbox'">
              <input type="radio" :id="$parent.$parent.id +'_'+ obj.title +'_'+ pos +'_'+ i" :name="obj.title" :value="n.title" v-if="n.type=='radio'">
              <label :for="$parent.$parent.id +'_'+ obj.title +'_'+ pos +'_'+ i"></label>
            </div>
            <div class="ListComp__label">
              {{ n.title }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: 'ListComp',
  props: ['obj', 'zindex', 'pos'],

  data: () => ({
    array: [],
    css_pos: '',
    css_size: 'normal',
  }),

  created() {


    _.each(this.obj.content, (content)=>{
      this.array.push(content)
    })

    if(this.pos != 0)
      this.css_pos = 'not-first'

    if(this.array.length > 15)
      this.css_size = 'big'

  },

}

</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.ListComp
  display flex
  flex-direction column
  padding-bottom 16px

  &.is-not-first
    border-left 1px solid grey
    padding-left 16px

  &__container
    padding 0 16px
    width 100%

  &__title
    padding 8px 0 16px

  &__list
    display flex
    flex-direction row
    flex-wrap wrap
    width 343px

    &.is-big
      width 560px

  &__checkbox
    display flex
    flex-direction row
    width 150px
    padding-right 14px

  &__label
    padding 14px 0 0 8px

  input[type=checkbox] + label 
    display block
    margin 8px 0
    cursor pointer

  input[type=checkbox] 
    display none
  
  input[type=checkbox] + label:before 
    content "\2715"
    border 1px solid grey
    border-radius 4px
    display inline-block
    // width 16px
    // height 16px
    // padding 4px
    width 12px
    height 16px
    padding 4px 6px
    vertical-align bottom
    color transparent
    transition .2s 

  input[type=checkbox] + label:active:before 
    transform scale(1)
  
  input[type=checkbox]:checked + label:before 
    background-color blue
    border-color blue
    color #fff
  
  input[type=checkbox]:disabled + label:before 
    transform scale(1)
    border-color #aaa
  
  input[type=checkbox]:checked:disabled + label:before 
    transform scale(1)
    background-color #bfb
    border-color #bfb


  input[type=radio] + label 
    display block
    margin 8px 0
    cursor pointer

  input[type=radio] 
    display none
  
  input[type=radio] + label:before 
    content ''
    background #fff
    border-radius 100%
    border 1px solid #afafaf
    display inline-block
    width 20px
    height 20px
    position relative
    top 4px
    margin-right 4px
    vertical-align top
    cursor pointer
    text-align center
    transition all 250ms ease

  input[type=radio] + label:active:before 
    transform scale(1)
  
  input[type=radio]:checked + label:before 
    background-color blue
    border-color blue
    color #fff
  
  input[type=radio]:disabled + label:before 
    transform scale(1)
    border-color #aaa
  
  input[type=radio]:checked:disabled + label:before 
    transform scale(1)
    background-color #bfb
    border-color #bfb





























  
</style>



