<template>
  <div class="Page p404">
    <div class="p404__text">
      {{ trans('text') }} 404
    </div>
  </div>
</template>

<script>
export default {
  name: 'p404',
  head: {
    meta: [
      { name: 'prerender-status-code', content: '404' }
    ]
  },
  translations: {
    fr: { text: 'oupsss erreur' },
    en: { text: 'oupsss error' }
  }
}
</script>

<style lang="stylus">
.p404
  height 100vh

  &__text
    position absolute
    top 50%
    transform translateY(-50%)
    font-size 20px
    width 100%
    text-align center
    font-weight 200
    letter-spacing 2px
    transition color .5s
</style>
