<template>
    <div class="Home" 
        @touchstart="touchStart($event)"
        @touchmove="touchMove($event)"
        @wheel="onWheel($event)">
        <transition @enter="enter" @leave="leave">
            <div ref="home" class="Home__container" :class="{ 'mobile': $root.is_desktop == false }">
                <Background />
                <div ref="experience" class="Home__experience">
                    <Visualisation v-if="$root.above('phone') && locked"/>
                    <Promotion ref="promotion"/>
                </div>
                <div ref="trigger" class="Home__trigger"></div>
                <div ref="sub" class="Home__sub">
                    <div ref="header" class="Home__header Flex__column-center">
                        <div ref="top" class="Home__header-block top">
                            <HeaderHome />
                        </div>
                    </div>
                    <div ref="thumbnails" class="Home__thumbnails">
                        <Thumbnails />
                    </div>
                </div>
                <Parcours v-if="$route.params.parcours" :key="$route.params.parcours"/>
                <div ref="navbackground" class="Home__nav-background" />    
            </div>
        </transition>
    </div>
</template>

<script>

import Thumbnails from '@/components/home/Thumbnails'
import Parcours from '@/components/parcours/Parcours'
import Background from '@/components/home/Background'
import Promotion from '@/components/home/Promotion'
import HeaderHome from '@/components/home/HeaderHome'
import Visualisation from '@/components/home/Visualisation'
import ScrollMagic from 'scrollmagic'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'

export default {
    name: 'Home',
    components: { Thumbnails, Parcours, Background, Promotion, HeaderHome, Visualisation },
    data: () => ({
        current: '',
        canScroll: true,
        locked: true,
        clientY: 0
    }),
    // beforeCreate() {
    //     if(!this.$route.params.categorie)
    //          this.$router.push({ params: { categorie: 'categorie-all' }})
    // },
    created() {

        if(!this.$route.params.categorie)
            this.$router.push({ params: { categorie: this.trans('Global.categorie-all') }})

        window.addEventListener('resize', this.onResize)
        this.$scenes = []
    },
    mounted() {
        this.$magic = new ScrollMagic.Controller()
        this.$timeline = null
        // this.onResize()
    },
    methods: {
        onResize() {

            // _.each(this.$scenes, (sc)=>{ sc.destroy() })
            // this.current = ''
            // this.locked = false
            // if(this.$tween) this.$tween.kill()
            // this.$tween = TweenMax.delayedCall(.5, ()=>{ 
            //     if(window.innerHeight <= 1024 && this.current != 'small') {
            //         this.current = 'small'
            //         this.addSecondMagic()
            //     } 
            //     else if(window.innerHeight > 1024 && this.current != 'large') {
            //         this.current = 'large'
            //         this.addMagic()
            //     } 
            //     this.locked = true 
            // })

            if(window.innerHeight <= 1024 && this.current != 'small') {

                this.current = 'small'
                this.addSecondMagic()
            } 

            else if(window.innerHeight > 1024 && this.current != 'large') {
                
            // else {

                this.current = 'large'
                this.addMagic()
            } 

            this.locked = false
            if(this.$tween) this.$tween.kill()
            this.$tween = TweenMax.delayedCall(1, ()=>{ this.locked = true })

        },
        hideNavigation() {
            TweenMax.to('.Navigation', .1, { alpha: 0 })
            TweenMax.set('.Navigation', { top: -90, delay: .1 })
        },
        showNavigation() {
            TweenMax.to('.Navigation', .2, { alpha: 1 })
            TweenMax.set('.Navigation', { top: 0 })
        },
        addMagic() {
            _.each(this.$scenes, (sc)=>{ sc.destroy() })
            this.$scenes = []
            this.$use_before = false
            var sc1 = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: -96,
                triggerHook: 0,
            })
            // .addIndicators()
            .on("leave", (evt)=> {
                TweenMax.to(this.$refs.navbackground, 0.5, { top: -100, ease: Expo.easeOut })
             })
            .addTo(this.$magic)

            this.$scenes.push(sc1)

            var sc2 = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: 96,
                triggerHook: 0,
            })

            // .addIndicators()

            .on("enter", (evt)=> { this.befroreEnterMagic() })
            .on("leave", (evt)=> { this.befroreLeaveMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc2)

            var sc3 = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: 1,
                triggerHook: 0,
            })
            .setPin(this.$refs.header)
            .setClassToggle(this.$refs.thumbnails, "active")

            // .addIndicators()

            .on("enter", (evt)=> { this.enterMagic() })
            .on("leave", (evt)=> { this.leaveMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc3)

            var sc4 = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: -(window.innerHeight-10),
                triggerHook: 0,
            })

            // .addIndicators()

            .on("enter", (evt)=> { this.$hidePromotion = new TimelineMax() .to(this.$refs.promotion.$el, 0.50, { alpha: 0, y: -220, ease: Expo.easeOut }) })
            .on("leave", (evt)=> { this.$hidePromotion = new TimelineMax() .to(this.$refs.promotion.$el, 1, { alpha: 1, y: 0, ease: Expo.easeOut }) })
            .addTo(this.$magic)

            this.$scenes.push(sc4)
        },
        addSecondMagic() {
            _.each(this.$scenes, (sc)=>{ sc.destroy() })
            this.$scenes = []

            this.$use_before = false

            const sc2 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: -96,
                triggerHook: 0,
            })

            // .addIndicators()
            // .on("enter", (evt)=> { this.hideNavigation() })
            // .on("leave", (evt)=> { this.showNavigation() })

            .addTo(this.$magic)

            this.$scenes.push(sc2)

            var sc3 = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: 1,
                triggerHook: 0,
            })
            .setPin(this.$refs.header)

            // .addIndicators()

            .on("enter", (evt)=> { this.enterSecondMagic() })
            .on("leave", (evt)=> { this.leaveSecondMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc3)

            var sc4 = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: -(window.innerHeight-10),
                triggerHook: 0,
            })

            // .addIndicators()

            .on("enter", (evt)=> { 
                this.$hidePromotion = new TimelineMax() 
                    .to(this.$refs.promotion.$el, 0.50, { 
                        alpha: 0, 
                        y: -220, 
                        ease: Expo.easeOut,
                        onComplete: ()=>{
                            TweenMax.set(this.$refs.promotion.$el, { y: -1000 })
                        } 
                    }) 
            })
            .on("leave", (evt)=> { 
                this.$hidePromotion = new TimelineMax() 
                    .set(this.$refs.promotion.$el, { y: -220 })
                    .to(this.$refs.promotion.$el, 1, { 
                        alpha: 1, 
                        y: 0, 
                        ease: Expo.easeOut
                    }) 
            })
            .addTo(this.$magic)

            this.$scenes.push(sc4)
        },
        leaveSecondMagic() {
            const sz = (this.$root.below('tablet')) ? 150 : 268
            TweenMax.to(this.$refs.header, 0.5, { height: sz, ease: Expo.easeOut })
        },
        enterSecondMagic() {
            const sz = (this.$root.below('tablet')) ? 140 : 180
            TweenMax.to(this.$refs.header, 0.5, { height: sz, ease: Expo.easeOut })
        },
        magicEnabled(b) {
            if(!this.$magic) return
            if(b){
                this.$magic.enabled(true)
                this.$magic.update(true)
            } else
                this.$magic.enabled(false)
        },
        resetHome() {
            this.magicEnabled(false)
            TweenMax.set(window, { scrollTo : 0, delay: .25 })
            TweenMax.set(this.$refs.navbackground, { top: -100 })
        },
        removeMagic() {
            if(this.$magic) {
                this.$magic.destroy(true)
                this.$magic = null
            }
        },
        promotionLeave() {
            const promise = new Promise((resolve, reject) => {
                TweenMax.from(this.$refs.experience, 0.75, { y: 2000, ease: Power3.easeOut, delay: .1 })
                TweenMax.from(this.$refs.sub, 0.75, { y: 2000, ease: Power3.easeOut, delay: .25 })
                TweenMax.from(this.$refs.thumbnails, 0.75, { y: 2000, ease: Power3.easeOut, delay: .4, onComplete: ()=>{
                    resolve()
                    TweenMax.set(this.$refs.experience, { clearProps: 'all' })
                    TweenMax.set(this.$refs.sub, { clearProps: 'all' })
                    TweenMax.set(this.$refs.thumbnails, { clearProps: 'all' })
                } })
            })
            return promise
        },
        resetPromotion() {
            const nw = document.querySelector('.Parcours__subheader').getBoundingClientRect()
            const el = this.$refs.promotion.$el.getBoundingClientRect()
            TweenMax.set(this.$refs.promotion.$el, { css:{ zIndex: 10000 } })
            TweenMax.from(this.$refs.promotion.$el, 0.75, {
                y: nw.top-el.top,
                ease: Cubic.easeOut,
                delay: .1,
            })
        },
        hideModePromotion(delay) {

            this.resetPromotion()
            const promise = new Promise((resolve, reject) => {
                TweenLite.from(this.$refs.sub, 0.75, { y: 2000, delay: delay, ease: Expo.easeOut })
                TweenLite.from(this.$refs.thumbnails, 0.75, { y: 600, onComplete: ()=>{
                    TweenMax.set(this.$refs.sub, { clearProps: 'all' })
                    resolve()

                }, delay: delay+.25, ease: Expo.easeOut })

            })
            return promise
        },
        befroreEnterMagic() {
            if(this.$use_before)
                TweenMax.to(this.$refs.top, 0.50, { width: window.innerWidth, ease: Expo.easeOut })
        },
        befroreLeaveMagic() {
            this.$use_before = !this.$use_before
            TweenMax.to(this.$refs.top, 0.50, { width: 1078, ease: Expo.easeOut })
        },
        resetAfterExplore() {
            this.magicEnabled(true)
            const promise = this.promotionLeave()
        },
        enterMagic() {
            if(this.$route.params.parcours)
                return
            if(this.$timeline)
                this.$timeline.kill()
            const sz = (this.$root.below('tablet')) ? 150 : 268
            this.$store.commit('app/HOME_NAVIGATION_LOCKED', true)
            this.$timeline = new TimelineMax()
                .add([
                    TweenMax.to(this.$refs.header, 0.5, { height: sz, ease: Expo.easeOut }),
                    TweenMax.to(this.$refs.navbackground, 0.5, { top: -2, height: 96, ease: Expo.easeOut }),
                    TweenMax.to(this.$refs.top, 0.50, { y: 44, ease: Expo.easeOut })
                ])
                .to(this.$refs.top, 0.50, { width: window.innerWidth, ease: Expo.easeOut })
        },
        leaveMagic() {
            if(this.$timeline)
                this.$timeline.kill()
            this.$store.commit('app/HOME_NAVIGATION_LOCKED', false)
            this.$timeline = new TimelineMax()
                .add([
                    TweenMax.to(this.$refs.header, 1, { height: 252, ease: Expo.easeOut }),
                    TweenMax.to(this.$refs.top, 1, { y: 0, ease: Expo.easeOut })
                ])
                .to(this.$refs.top, 0.50, { width: 1078, ease: Expo.easeOut })
        },
        enter(done) {
            TweenMax.set(this.$refs.experience, { y: 2000 })
            TweenMax.set(this.$refs.sub, { y: 2000 })
            TweenMax.set(this.$refs.thumbnails, { y: 2000 })
            const promise = this.$parent.$refs.front.hide()
            promise.then(() => {
                TweenMax.to(this.$refs.experience, 0.75, { y: 0, ease: Power3.easeOut, delay: .1 })
                TweenMax.to(this.$refs.sub, 0.75, { y: 0, ease: Power3.easeOut, delay: .25 })
                TweenMax.to(this.$refs.thumbnails, 0.75, { y: 0, ease: Power3.easeOut, delay: .4, onComplete: ()=>{
                    done()
                    TweenMax.set(this.$refs.experience, { clearProps: 'all' })
                    TweenMax.set(this.$refs.sub, { clearProps: 'all' })
                    TweenMax.set(this.$refs.thumbnails, { clearProps: 'all' })
                } })
            })
        },
        leave(done) {
            const promise = this.$parent.$refs.front.show()
            promise.then(() => {
                done()
                this.removeMagic()
            })
        },
        touchStart(event) {
            if(window.navigator.userAgent.indexOf('Version/12') != -1 
            && window.navigator.userAgent.indexOf('iPhone') != -1 
            || window.navigator.userAgent.indexOf('Version/13') != -1
            && window.navigator.userAgent.indexOf('iPhone') != -1)
            return
            this.clientY = event.changedTouches[0].clientY
        },
        touchMove(event) { 
            if(window.navigator.userAgent.indexOf('Version/12') != -1 
            && window.navigator.userAgent.indexOf('iPhone') != -1 
            || window.navigator.userAgent.indexOf('Version/13') != -1
            && window.navigator.userAgent.indexOf('iPhone') != -1)
            return    
            if(!this.canScroll)
                return
            event.preventDefault() 
            const deltaY = event.changedTouches[0].clientY - this.clientY
            if(!this.$route.params.parcours) {
                const el = document.querySelector('.Home__container')
                const delta = -el.scrollTop + (deltaY * 1.7)
                TweenMax.to(el, .6, { scrollTo: -delta, ease: Power2.easeOut })

            } else {
                const el = document.querySelector('.Parcours')
                let delta = -el.scrollTop + (deltaY * 1.7)
                TweenMax.to(el, .5, { scrollTo: -delta , ease: Power2.easeOut })   
            } 
        },
        onWheel(event) {
            if(!this.canScroll)
                return
            event.preventDefault()
            const speed = this.$root.is_firefox ? 1.7 : 1.7
            if(!this.$route.params.parcours) {
                const el = window
                const delta = el.pageYOffset + (event.deltaY * speed)
                TweenMax.to(el, .6, { scrollTo: delta, ease: Power2.easeOut })
            } else {
                const el = document.querySelector('.Parcours')
                const delta = el.scrollTop + (event.deltaY * speed)
                TweenMax.to(el, .6, { scrollTo: delta, ease: Power2.easeOut })
            }
        },
        preventDefault(e) {
            e = e || window.event
            if(e.preventDefault) e.preventDefault()
            e.returnValue = false
        },
        preventDefaultForScrollKeys(e) {
            const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }
            if(keys[e.keyCode]) { this.preventDefault(e); return false }
        },
        disableScroll() {
            this.canScroll = false
            document.addEventListener('wheel', this.preventDefault, { passive: false })
            document.addEventListener('touchmove', this.preventDefault, { passive: false })
            window.onwheel = window.onmousewheel = document.onmousewheel = window.ontouchmove = this.preventDefault
            document.onkeydown = this.preventDefaultForScrollKeys
        },
        enableScroll() {
            this.canScroll = true
            document.removeEventListener('wheel', this.preventDefault, { passive: false })
            document.removeEventListener('touchmove', this.preventDefault, { passive: false })
            window.onmousewheel = document.onmousewheel = window.onwheel = window.ontouchmove = document.onkeydown = null
        },

    },

    watch: {
        '$route': {
            immediate: true,
            handler() {}
        },
        toogle_search(nw, od) {
        },
    },
    computed: {
        toogle_search() { return this.$store.getters['app/toogle_search'] },
    }

}
</script>

<style lang='stylus'>
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Home
    overflow hidden
    position absolute
    width 100vw
    opacity 1

    &__container

        &.mobile
            position fixed
            width 100vw
            height 100%
            top 0
      
            overflow-y auto
            overflow-x hidden
            // -webkit-overflow-scrolling touch

    &__sub
        position relative
        background rgb(2,0,36)
        background linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(232,224,209,1) 0%, rgba(247,243,235,1) 100%)
        margin-top 60px

    &__experience
        position relative
        width 100%
        height 100vh

    &__trigger
        min-height 1px

    &__header
        width 100%
        height 252px
        background-color white
        color black
        z-index 500
        opacity 1
        top -60px
        position relative

        +below(tablet)
            height 150px

    &__header-block
        max-width 1078px
        width 90%

        &.top
            height 172px

    &__header-block-bottom
        padding 32px 64px
        width 38%

    &__thumbnails
        width 100%
        position relative
        z-index 480
        padding-bottom 8px

    &__nav-background
        position fixed
        background-color white
        border-bottom 2px solid #f2f2f2
        width 100%
        height 96px
        top -100px
        left 0
        z-index 2750

.active
    z-index 400

</style>












