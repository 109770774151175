export default {
  namespaced: true,
  state: {
    general: {},
    pages: {},
    parcours: { fr: {}, en: {} },
    categories: { fr: {}, en: {} },
  },
  mutations: {
    'RESET' (state) {
      state.general = {}
      state.pages = {}
      state.parcours = null
      state.recent = null
    },

    'SET_DATA' (state, res) {

      if(res.type == 'general')
        state.general = res.data.acf

      if(res.type == 'pages')
        _.each(res.data, data => state.pages[data.acf.admin.route_key] = {  ...data.acf, id: data.id  })

      if(res.type == 'parcours') {
        state.parcours = { fr: {}, en: {} }
        _.each(['fr', 'en'], lang => {
          _.each(res.data.parcours[lang].parcours, data => state.parcours[lang][data.id] = {  ...data })
          state.categories[lang] = res.data.parcours[lang].categories
        })
      }
    },
  },
  getters: {
    general: state => state.general,
    pages: state => state.pages,
    parcours (state, getters, rootState) {
      return state.parcours[rootState.app.lang]
    },
    categories (state, getters, rootState) {
      return state.categories[rootState.app.lang]
    }
  }
}
