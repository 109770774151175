<template> 
  <div class="RowComp" :class="'is-'+ type">  
    <template v-for="(n, t, p) in obj">  
      <LabelComp v-if="t=='labels'" :obj="n" :zindex="zindex-p" />
      <ConditionComp v-if="t=='conditions'" :obj="n" :zindex="zindex-p" />
      <InputComp v-if="n=='title' && typeof n == 'string' || n=='topic'" :obj="n" :zindex="zindex-p" />
      <ListComp v-if="n.type=='list'" :obj="n" :zindex="zindex-p" :pos="p" />
      <DateComp v-if="n=='date'" :obj="n" :zindex="zindex-p" />
    </template>
  </div>
</template>

<script>

import LabelComp from './comp/LabelComp'
import ConditionComp from './comp/ConditionComp'
import InputComp from './comp/InputComp'
import ListComp from './comp/ListComp'
import DateComp from './comp/DateComp'

export default {

  name: 'RowComp',
  components: { LabelComp, ConditionComp, InputComp, ListComp, DateComp },
  props: ['obj', 'pos'],

  data: () => ({
    type: 'normal',
    zindex: 1000
  }),

  created() {
    this.zindex -= this.pos*10
    if(this.pos == 0)
      this.type = 'top'
  },
}

</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.RowComp
  display flex
  flex-direction row
  flex-wrap wrap
  min-height 32px
  margin 0 20px
  border-top 1px solid #afafaf
  padding 10px 0

  &.is-top 
    border-style none

</style>



