<template>
  <div class="Background"> 
    <div ref="container" class="Background__container">
      <div class="Background__cover" :style="{ backgroundImage: 'url('+ this.image +')' }"  :class="{ 'filter' : $promo }"/>
    </div>
    <div ref="gradient" class="Background__gradient" />
    <div class="Background__over" />
  </div>
</template>

<script>
export default {
  name: 'Background',
  data: ()=>({ 
    image: null
  }),

  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
  },

  mounted() {
 
    this.$promo = this.parcours[this.$store.getters['data/pages'].home.promo[this.lang]].cover
    this.image = (this.$promo) ? this.$promo : require('@/commons/img/camera_blue.png') 

  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Background
  position fixed
  pointer-events none
  
  &__container
    width 100vw
    height 100vh
    background-color beige
    opacity 1
    background-size cover
    background-position top center

  &__cover
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    transition transform 1s
    background-size cover
    background-position top center

    &.filter
      filter sepia(100%) hue-rotate(180deg)

  &__gradient
    background rgb(0,0,0)
    background linear-gradient(180deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0) 100%)
    top 0
    left 0
    position fixed
    width 100%
    height 100%

  &__over
    background black
    opacity .2
    top 0
    left 0
    position fixed
    width 100%
    height 100%
    
</style>

