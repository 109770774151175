<template>
    <transition @enter="enter" @leave="leave">
    <div class="Parcours" v-if="chapters">
        <ImageExpanded :id="id" ref="imageExpanded" v-if="ready" />
        <div ref="container" class="Parcours__container">
            <div class="Parcours__empty">
                <div class="Parcours__spin" v-if="chapters.visible.length == 0" />
                <div ref="header" class="Parcours__header" :class="current">
                    <transition @enter="enterHeader" @leave="leaveHeader">
                        <HeaderParcours
                          :chapters="chapters.visible"
                          :parcours="parcours_data[id]"
                          :special="current"
                          ref="headerParcours"
                          class="Parcours__subheader"
                          v-if="chapters.visible.length > 0"
                        />
                    </transition>
                    <comp v-if="!is_linear && chapters.visible.length > 0" :is="$root.below('tablet') ? 'MobileControls' : 'Controls'"
                        class="Parcours__control" :class="$root.below('tablet') ? 'mobile' : current" :current="current" />
                </div>
            </div>
            <div ref="sub" class="Parcours__sub Flex__column-center-start"  :class="[{ 'is-linear': is_linear }, current]" v-if="ready">
                <div ref="block" class="Parcours__block">
                    <template v-if="chapters.visible.length > 0 && current_chapter == 0">

                      <Chapter
                        v-for="(chapter, index) in chapters.introduction"
                        :key="'intro__' + index"
                        :chapter="chapter"
                        :index="null"
                        :is_linear="is_linear"
                        :isIntro="true"
                      />

                      <router-link
                        class="Parcours__pdf"
                        target="_blank"
                        :to="{ name: 'pdf.' + lang, params: { slug: $route.params.parcours } }">
                        <svg viewBox="0 0 330 330">
                          <path d="M154.4,265.6c0.4,0.4,0.7,0.7,1.1,1c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.4,0.3,0.7,0.5
                            c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.7,0.2
                            c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.5,0.1,0.8,0.1c0.2,0,0.4,0.1,0.7,0.1
                            c0.5,0,1,0.1,1.5,0.1s1,0,1.5-0.1c0.2,0,0.4-0.1,0.7-0.1c0.3,0,0.5-0.1,0.8-0.1c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.1,0.7-0.2
                            c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.6-0.4
                            c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.1,0.4-0.3,0.5-0.4c0.4-0.3,0.7-0.6,1-0.9c0,0,0,0,0,0l70-70
                            c5.9-5.9,5.9-15.4,0-21.2c-5.9-5.9-15.4-5.9-21.2,0L180,218.8V15c0-8.3-6.7-15-15-15s-15,6.7-15,15v203.8l-44.4-44.4
                            c-5.9-5.9-15.4-5.9-21.2,0c-5.9,5.9-5.9,15.4,0,21.2L154.4,265.6z"/>
                          <path d="M315,300H15c-8.3,0-15,6.7-15,15s6.7,15,15,15h300c8.3,0,15-6.7,15-15S323.3,300,315,300z"/>
                        </svg>
                        {{ trans('pdf') }}
                      </router-link>
                        <!--div class="Parcours__title-editorial" v-if="ready">
                            <div>
                                <div class="Parcours__intro-titre">{{ trans('author.parcours') }}</div>
                                <div class="Parcours__intro-author">{{ parcour.author.parcours }}</div>
                            </div>
                            <div>
                                <div class="Parcours__intro-titre">{{ trans('author.editorial') }}</div>
                                <div class="Parcours__intro-author">{{ parcour.author.editorial }}</div>
                            </div>
                        </div>
                        <div class="Parcours__introduction" v-html="parcour.introduction"/-->
                    </template>
                  <Chapter
                    v-for="(chapter, index) in chapters.visible"
                    :index="index"
                    :key="index"
                    :chapter="chapter"
                    :is_linear="is_linear"
                    v-if="is_linear"
                  />
                  <Chapter
                    :index="current_chapter"
                    :key="current_chapter"
                    :chapter="chapters['visible'][current_chapter]"
                    :is_linear="is_linear"
                    v-if="!is_linear && chapters.visible.length > 0"
                  />
                </div>
            </div>
           <!--
           <div class="Parcours__footer Flex__column-center-start">
                <div class="Parcours__block">
                    <div class="Page__title Parcours__title">Des parcours à découvrir</div>
                    <ExploreParcours id="1"/>
                    <ExploreParcours id="1"/>
                </div>
            </div>
            -->
        </div>
        <div ref="navbackground" class="Parcours__nav-background" />
    </div>
    </transition>
</template>

<script>

import ImageExpanded from '@/components/parcours/ImageExpanded'
import HeaderParcours from '@/components/parcours/HeaderParcours'
import ExploreParcours from '@/components/parcours/ExploreParcours'
import Controls from '@/components/parcours/Controls'
import MobileControls from '@/components/parcours/MobileControls'
import Chapter from '@/components/chapter/Chapter'
import ScrollMagic from 'scrollmagic'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'

export default {
    name: 'Parcours',
    components: { ImageExpanded, HeaderParcours, ExploreParcours, Controls, Chapter, MobileControls },
    data: () => ({
      ready: false,
      id: null,
      is_linear: null,
      chapters: { visible: [], hidden: [] },
      current_chapter: null,
      parcour: null,
      current: '',
      context: ''
    }),
    translations: {
      en: {
        pdf: 'Download PDF',
        author: {
          parcours: 'Parcours editor(s):',
          editorial: "Responsable(s) de l'éditorialisation:",
        },
      },
      fr: {
        pdf: 'Télécharger le PDF',
        author: {
          parcours: 'Responsable(s) du parcours:',
          editorial: "Responsable(s) de l'éditorialisation:",
        },
      }
    },
    beforeDestroy() {
        if(this.navigation_locked) this.$store.commit('app/RESET_NAVIGATION')
        window.removeEventListener('resize', this.onResize)
        this.$parent.showNavigation()
        this.removeMagic()
        document.documentElement.classList.remove('no-scroll-bar')
    },
    created() {
        window.addEventListener('resize', this.onResize)
        this.$scenes = []
        document.documentElement.classList.add('no-scroll-bar')
    },

    mounted: async function() {
        this.id = this.$route.params.parcours
        const parcour = this.parcours_data[this.id]
        this.parcour = parcour
        this.is_linear = (parcour && parcour.is_linear === false) ? false : true

        // this.is_linear = true

        this.$thumb = document.querySelector('.Thumbnail.is-' + this.id)
        this.$header_size = document.querySelector('.Parcours__header').getBoundingClientRect().width

        if(document.querySelector('.Home__thumbnails.active')) TweenMax.set(this.$el, { css:{ zIndex: 450 } })
        else  TweenMax.set(this.$el, { css:{ zIndex: 5500 } })

        this.$magic = new ScrollMagic.Controller({ container: this.$el })
        // this.onResize() // this.addMagic()

        if(this.$route.params.chapter && this.is_linear) this.scrollToChapter(this.$route.params.chapter)
        // else if(this.$route.params.chapter && !this.is_linear) this.scrollToTop()

        this.$root.commonsTranslations = {
          en: { Route: { params: { parcours: this.lang == 'en' ? parcour.id : parcour.slug_reverse }} },
          fr: { Route: { params: { parcours: this.lang == 'fr' ? parcour.id : parcour.slug_reverse }} },
        }

        this.current_chapter = (this.$route.params.chapter) ? this.$route.params.chapter-1 : 0
        this.ready = true
        this.chapters = await this.getChapters(parcour.chapters)
        // if(!this.chapters) return
        this.onResize() // this.addMagic()
    },
    methods: {
        onResize() {
            if(window.innerHeight <= 900 && window.innerWidth > 1024 && this.current != 'small') {
                this.current = 'special'
                this.addThirdMagic()
            } else if(window.innerWidth <= 1024 && this.current != 'small') {
                this.current = 'small'
                this.addSecondMagic()
            } else if(window.innerWidth > 1024 && this.current != 'large') {
                this.current = 'large'
                this.addMagic()
            }
        },
        scrollToChapter(id) {
            return
            // const el = document.querySelector('.Parcours')
            // TweenMax.to(el, 0, { scrollTo: { y: '.Chapter.is-'+ id, offsetY: 380 }, ease: Expo.easeOut, delay: 1 })
            // this.$nextTick(()=>{
            //     TweenMax.from(this.$refs.block, 0.5, { alpha: 0, delay: 1.75, onComplete: ()=>{ TweenMax.set(this.$refs.block, { clearProps: 'all' }) } })
            // })
        },
        scrollOnChapter(id) {
            const el = document.querySelector('.Parcours')
            if(!this.$root.below('tablet')) {
                TweenMax.to(this.$refs.block, 0.2, { alpha: 0, ease: Linear.easeNone })
                TweenMax.to(el, 0.5, { scrollTo: { y: 220 }, ease: Cubic.easeOut })
                TweenMax.to(el, 2.5, { scrollTo: { y: '.Chapter.is-'+ id, offsetY: 380 }, ease: Cubic.easeOut, delay: 0.5, onComplete: ()=>{ this.scrollToChapterCompleted() }  })
                TweenMax.from(this.$refs.block, 0.5, { alpha: 0, delay: 1.25 })
            } else {
                const el = document.querySelector('.Parcours')
                TweenMax.to(this.$refs.block, 0.2, { alpha: 0.5, ease: Linear.easeNone })
                TweenMax.to(el, 2.5, { scrollTo: { y: '.Chapter.is-'+ id, offsetY: 200 }, ease: Cubic.easeOut, delay: 0.5, onComplete: ()=>{ this.scrollToChapterCompleted() }  })
            }
        },
        scrollToChapterCompleted() {
            TweenMax.set(this.$refs.block, { clearProps: 'all' })
            this.$refs.headerParcours.scrollToChapterCompleted()
        },

        scrollToTop() {
            // console.log('scrollToTop');
            const el = document.querySelector('.Parcours')
            const h = this.$root.below('portrait') ? window.innerHeight - 166 : window.innerHeight
            let py =  !this.$root.below('tablet') ? 140 : h
            if(this.$root.is_desktop)
                py = window.innerHeight*.2

            TweenMax.to(el, 1, { scrollTo: { y: py }, ease: Expo.easeOut })
        },
        addMagic() {
            _.each(this.$scenes, (sc)=>{ sc.destroy() })
            this.$scenes = []

            // this.$timeline = new TimelineMax()
            //     .to(this.$refs.navbackground, 1, { css: { top: 0 }, ease: Expo.easeOut })

            // const sc1 = new ScrollMagic.Scene({
            //     triggerElement: this.$refs.header,
            //     offset: -268,
            //     triggerHook: 0,
            //     duration: 172
            // })
            // // .addIndicators()
            // .setTween(this.$timeline)
            // .addTo(this.$magic)

            // this.$scenes.push(sc1)

            const sc2 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: -186,
                triggerHook: 0,
            })
            // .addIndicators()
            .on("leave", (evt)=> { this.leaveMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc2)

            const sc3 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: -96,
                triggerHook: 0,
            })
            // .addIndicators()
            .setPin(this.$refs.header)
            .on("enter", (evt)=> { this.enterMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc3)
        },

        addSecondMagic() {
            _.each(this.$scenes, (sc)=>{ sc.destroy() })
            this.$scenes = []

            const sc2 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: -96,
                triggerHook: 0,
            })
            // .addIndicators()
            // .on("enter", (evt)=> { this.$parent.hideNavigation() })
            .on("leave", (evt)=> { this.leaveSecondMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc2)

            const sc3 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: 0,
                triggerHook: 0,
            })
            // .addIndicators()
            .setPin(this.$refs.header)
            // .on("enter", (evt)=> { this.enterSecondMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc3)
        },


        addThirdMagic() {
            _.each(this.$scenes, (sc)=>{ sc.destroy() })
            this.$scenes = []

            const sc2 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: -96,
                triggerHook: 0,
            })
            // .addIndicators()
            .on("enter", (evt)=> { this.$parent.hideNavigation() })
            .on("leave", (evt)=> { this.leaveThirdMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc2)

            const sc3 = new ScrollMagic.Scene({
                triggerElement: this.$refs.header,
                offset: 0,
                triggerHook: 0,
            })
            // .addIndicators()
            .setPin(this.$refs.header)
            .on("enter", (evt)=> { this.enterThirdMagic() })
            .addTo(this.$magic)

            this.$scenes.push(sc3)
        },

        resetNavbackground() {
            TweenMax.set(this.$refs.navbackground, { css: { top: -268 } })
        },

        enterSecondMagic() {
            this.$refs.headerParcours.expand(true)
            this.$tween = new TimelineMax()
                .to(this.$refs.header, 0.50, { width: '102vw', ease: Cubic.easeOut })
        },
        leaveSecondMagic() {
            return
            this.$parent.showNavigation()
            this.$tween = new TimelineMax()
                .to(this.$refs.header, 0.25, {  width: this.$header_size, ease: Linear.easeNone  })
        },

        enterThirdMagic() {
            this.$refs.headerParcours.expand(true)
            this.$tween = new TimelineMax()
                .to(this.$refs.header, 0.50, { width: '102vw', ease: Cubic.easeOut })
        },
        leaveThirdMagic() {
            this.$refs.headerParcours.expand(false)
            this.$parent.showNavigation()
            this.$tween = new TimelineMax()
                .to(this.$refs.header, 0.25, {  width: this.$header_size, ease: Linear.easeNone  })
        },

        enterMagic() {

            if(this.context == 'enterMagic')
                return
            this.context = 'enterMagic'

            this.$refs.headerParcours.expand(true)
            this.$store.commit('app/TOOGLE_NAVIGATION')
            this.$tween = new TimelineMax()
                .set(this.$refs.navbackground, { css: { top: -268 } })
                .to(this.$refs.header, 0.50, { width: window.innerWidth, ease: Cubic.easeOut })
        },
        leaveMagic() {

            if(this.context == 'leaveMagic')
                return
            this.context = 'leaveMagic'

            this.$refs.headerParcours.expand(false)
            this.$store.commit('app/RESET_NAVIGATION')
            this.$tween = new TimelineMax()
                .to(this.$refs.header, 0.25, {  width: this.$header_size, ease: Linear.easeNone  })
        },

        removeMagic() {
            if(this.$magic){
                this.$magic.destroy(true)
                this.$magic = null
            }
        },

        onClick() {
            this.$router.push({ params: { parcours: null, chapter: null }})
        },

        regularEnter(el, done) {
            TweenMax.from(this.$refs.container, 1, { y: 2000, ease: Power3.easeOut, delay: 1 })
            TweenMax.from(this.$refs.block, 1, { y: 200, ease: Power3.easeOut, delay: 1.35, onComplete: ()=>{
                done()
                TweenMax.set(this.$refs.container, { clearProps: 'all' })
                TweenMax.set(this.$refs.block, { clearProps: 'all' })
            } })
        },

        promotionEnter(el, done) {
            const nw = document.querySelector('.Home__header').getBoundingClientRect()
            TweenMax.set(this.$refs.container, { y: nw.top-384-(172/2)-64 })
            TweenMax.set(this.$refs.sub, { y: 2000 })
            this.$nextTick(() => {
                const promise = this.$refs.imageExpanded.showModePromotion()
                promise.then(() => {
                    TweenMax.to(this.$refs.container, 0.75, { y: 0, ease: Power3.easeOut })
                    TweenMax.to(this.$refs.sub, 0.75, { y: 0 , ease: Cubic.easeOut, delay: 0.1, onComplete: ()=>{
                        done()
                        TweenMax.set(this.$refs.container, { clearProps: 'all' })
                        TweenMax.set(this.$refs.sub, { clearProps: 'all' })
                    } })
                })
            })
        },

        exploreEnter(el, done) {
            TweenMax.from(this.$refs.container, 1, { y: 2000, ease: Power3.easeOut, delay: 0.25 })
            TweenMax.from(this.$refs.block, 1, { y: 200, ease: Power3.easeOut, delay: 0.6, onComplete: ()=>{
                done()
                TweenMax.set(this.$refs.container, { clearProps: 'all' })
                TweenMax.set(this.$refs.block, { clearProps: 'all' })
            } })
        },

        exploreLeave(el, done) {
            if(!this.route.to.params.parcours) {
                this.$parent.resetAfterExplore()
                this.$timeline = new TimelineMax()
                    .set(this.$refs.container, { alpha: 0 })
                    .to(this.$el, 0.5, { alpha: 0, ease: Linear.easeNone, onComplete: ()=>{
                        this.$store.commit('app/REVERSE_COMPLETED')
                        done()
                    } })
            }
            else
                done()
        },

        expandImage(id) {
            this.$store.commit('app/RESET_NAVIGATION')
            this.$refs.imageExpanded.expandImage(id)
        },

        regularLeave(el, done) {
            if(this.navigation_locked) {
                TweenMax.set(this.$refs.block, { alpha: 0 })
                TweenMax.set(this.$refs.header, { alpha: 0 })
                TweenMax.to(this.$refs.sub, 0.5, { alpha: 0, ease: Linear.easeNone })
                this.$refs.imageExpanded.hideRegular()
                this.$store.commit('app/RESET_NAVIGATION')
                TweenMax.delayedCall(1.5, ()=>{
                    done()
                    this.removeMagic()
                })
                return
            }
            this.$refs.imageExpanded.hideRegular()
            TweenMax.to(this.$refs.container, 1, { y: 2000, ease: Power3.easeOut, delay: 0.35, onComplete: ()=>{
                done()
                this.removeMagic()
            } })
            TweenMax.to(this.$refs.block, 1, { y: 200, ease: Power3.easeOut, delay: 0 })
            TweenMax.set(this.$el, { css:{ zIndex: 450 }, delay: 1 })
        },

        promotionLeave(el, done) {
            if(this.navigation_locked) {
                TweenMax.set(this.$refs.block, { alpha: 0 })
                TweenMax.set(this.$refs.header, { alpha: 0 })
                TweenMax.to(this.$el, 0.5, { alpha: 0, ease: Linear.easeNone, delay: .1 })
                this.$store.commit('app/RESET_NAVIGATION')
                const promise = this.$parent.promotionLeave()
                promise.then(() => {
                    done()
                })
                return
            }
            TweenMax.set(this.$refs.header, { alpha: 0 })
            TweenMax.to(this.$refs.sub, 0.75, { y: 2000 , ease: Cubic.easeOut })
            this.$refs.imageExpanded.hideModePromotion(1)
            const promise = this.$parent.hideModePromotion(1.2)
                  promise.then(() => {
                    done()
                    this.removeMagic()
                })
        },

        enter(el, done) {
            TweenMax.set(this.$parent.$refs.promotion.$el, { css:{ zIndex: 5000 } })
            this.$nextTick(()=>{
                switch(this.parcours) {
                    case 'thumbnail' :
                        this.regularEnter(el, done)
                        break
                    case 'promotion':
                        this.promotionEnter(el, done)
                        break
                    case 'explore':
                        this.$parent.resetHome()
                        this.exploreEnter(el, done)
                        break
                    default :
                        done()
                }
            })
        },

        leave(el, done) {
            if(this.$root.below('tablet')){
                this.resetNavbackground()
                const promise = this.$parent.$parent.$refs.front.show()
                promise.then(() => {
                    done()
                    this.removeMagic()
                    this.$store.commit('app/REVERSE_COMPLETED')
                    TweenMax.delayedCall(.25, ()=>{
                        this.$parent.$parent.$refs.front.hide()
                    })
                })
                return
            }
            switch(this.parcours) {
                case 'thumbnail':
                    this.regularLeave(el, done)
                    break
                case 'promotion':
                    this.promotionLeave(el, done)
                    break
                case 'explore':
                     this.exploreLeave(el, done)
                    break
                default:
                    done()
            }
            this.resetNavbackground()
        },

        enterHeader(el, done) {
            TweenMax.from(el, .5, { alpha: 0, onComplete: ()=> { done() } })
        },
        leaveHeader(el, done) {
            done()
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                if(this.route.from.params.chapter != this.route.to.params.chapter && !this.is_linear) {
                    this.current_chapter = this.$route.params.chapter-1
                    // if(!this.$root.below('tablet'))
                    this.scrollToTop()
                }
            }
        },
        expand_middle(nw, od) {
            TweenMax.set(this.$el, { css:{ zIndex: 5000 } })
        },
    },
    computed: {
        mode() { return this.$store.getters['app/mode'] },
        route() { return this.$store.getters['app/route'] },
        expand_middle() { return this.$store.getters['app/expand_middle'] },
        navigation_locked() { return this.$store.getters['app/navigation_locked'] },
        parcours_data() { return this.$store.getters['data/parcours'] },
        parcours() { return this.$store.getters['app/parcours'] }
    }
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Parcours
    position fixed
    width calc(100vw + 1px)
    color black
    height 100vh
    top 0
    z-index 450
    overflow-y scroll
    overflow-x hidden
    -webkit-overflow-scrolling touch

    &__pdf
      margin 20px 0 44px 32px
      display inline-block
      border 1px solid blue
      border-radius 10px
      padding 10px
      color blue
      font-size 12px
      svg
        fill blue
        width 12px
        margin-right 5px
      &:hover
        color blue

    &__intro
      &-titre
        font-size 14px
        margin 0 !important
      &-author
        font-size 22px
        margin-top 10px !important
        margin-bottom 30px

    &__introduction
      margin 40px 108px 0 56px
      line-height 30px
      white-space pre-line


    &__title-editorial
      border-left 2px solid grey
      padding-left 25px
      margin 56px
      div
        &:last-child
          margin-top 20px

    &__spin
      border 2px solid rgba(white, .5)
      border-top 2px solid rgba(white, .1)
      border-radius 50%
      width 40px
      height 40px
      animation Parcours__spin 2s linear infinite
      top 50%
      left calc(50% - 20px)
      transform translate(-50%, -50%)
      position absolute
      @keyframes Parcours__spin {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }
      }

    &__container
        // min-height 200vh
        width 100%
        position relative

    &__block
        width 100%
        max-width 1024px
        z-index 2000

    &__sub
        position relative
        background rgb(238,231,217)
        background linear-gradient(180deg, rgba(238,231,217,1) 0%, rgba(255,253,243,1) 100%)
        padding-top 356px
        min-height 100vh

        +below(portrait)
            padding 160px 0 80px

        &.is-linear
            padding-top 206px
            +below(tablet)
                padding-top 120px

        &.special
            padding-top 220px

    &__empty
        position relative
        width 100%
        height 30vh
        // height 384px

        +below(tablet)
            height calc(100vh - 84px)

        +below(phone)
            height calc(100vh - 84px)

    &__trigger
        min-height 1px

    &__header
        height 172px
        width 1078px
        position absolute
        left 50%
        transform translateX(-50%)
        margin-left 7px
        bottom -85px
        z-index 3000

        +below(tablet)
            width 100vw
            margin 0
            background-color white
            opacity 1

        +below(phone)
            height 160px

        &.special
            height 130px

    &__subheader
        position relative
        z-index 2950

    &__control
        position absolute
        top 86px
        z-index 2900
        left 50%
        transform translateX(-50%)
        width 101vw

        +below(tablet)
            width 102vw
            &.mobile
                top initial

    &__nav-background
        position fixed
        top -268px
        left 0
        width 100%
        height 268px
        background-color white
        z-index 2750

    &__footer
        width 100%
        min-height 200px
        background-color white

        +below(tablet)
            padding-left 48px

    &__title
        padding 64px 0

    &__description
        max-width 768px
        font-size 20px
        line-height 32px
        color #474443
        padding-left 42px
        padding-bottom 150px

        +below(tablet)
            font-size 16px
            line-height 24px

</style>



















