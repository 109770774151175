<template> 
  <div class="ConditionComp" :style="{ zIndex: zindex }" :class="getClass()">  
    <Dropdown ref="dropdown" :items="array" idx="search" clickOnItem="clickOnItem" :scroll="$scroll" v-if="ready && array.length > 0"/>
  </div>
</template>

<script>

import Dropdown from '@/components/app/Dropdown'

export default {
  name: 'ConditionComp',
  components: { Dropdown },
  props: ['obj', 'zindex'],
  data: () => ({
    ready: false,
    array: [],
  }),
  created() {
    this.$scroll = document.querySelector('.Search')
  },
  mounted() {
    _.each(this.obj, (n, i)=>{
      const o = { id: n, title: n, order: i }
      this.array.push(o)
    })
    this.ready = true
  },

  methods: {
    getClass() {
      if(this.array.length === 0) return 'is-empty'
      else return ''
    },
    clickOnItem(id) {
    },
  },
}

</script>

<style lang='stylus'>
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.ConditionComp
  position relative
  z-index 900
  width 100%
  max-width 136px

  &.is-empty
    max-width 136px

  .Dropdown
    width 136px
    position absolute
    top 6px

</style>



