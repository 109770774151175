<template>
  <div class="Control" :class="{ 'active': active }">
    <div class="txt" :class="current" v-html="txt" @click="onClick(id)"/>
    <svg 
      class="Control__arrow" 
      viewBox="0 0 22.19 57.96" 
      :class="{ 'active': active && this.id == 1 }">
      <line class="cls-1" x1="11.09" x2="11.09" y2="57.2"/>
      <polyline class="cls-1" points="0.38 44.83 11.09 57.2 21.81 44.83"/>
    </svg>
  </div>
</template>

<script>

import Settings from '@/config/Settings'

export default {

  name: 'Control',
  props: ['id', 'txt', 'current', 'length'],
  data: () => ({
    active: false
  }),
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.onHideGradient()
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.setText()
    this.reset()
  },
  methods: {
    onResize() {
      this.setText()
    },
    setText() {
      if(this.txt.length > 4) {
        const split = _.split(this.txt, ' ')
        let txt = ''
        for(let c=0; c<4; c++) {
          txt += split[c] + ' '
        }
        txt += '...'
        if(split.length > 5)
        this.txt = txt
      }
    },
    reset() {
      if(Number(this.$route.params.chapter) === this.id
      || !this.$route.params.chapter && this.id == 1 ) this.active = true
      else this.active = false
    },
    onClick(id) {
      this.$router.push({ params: { chapter: id }})
    },
  },
  watch: {
    '$route': {
        immediate: true,
        handler() {
          this.reset()
          this.$nextTick(()=>{ this.setText() })
      }
    },
  },
}
</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Control
  position relative
  width 100%
  height 100%
  transition background-color 0.5s ease

  &.active
    background white

  &__arrow
    position absolute
    left 16px
    top 5px
    width 16px
    opacity 0
    transition opacity 0.5s ease

    &.hide
      display block

    &.active
      opacity 1

  .txt
    position absolute
    cursor pointer
    padding 14px 18px
    bottom 0

    +below(tablet)
      font-size 13px

    &.special
      font-size 12px
      line-height 14px

  .cls-1
    fill none
    stroke #dce0d1
    stroke-miterlimit 10

</style>






























