<template>
  <div class="Asset">
    <template v-if="asset && !Array.isArray(asset)">


      <div ref="text" v-html="asset.html" />
      
      <!--div class="Asset__creator" v-if="asset.creator" v-html="asset.creator" /-->
      <div class="Asset__description" v-if="asset.description" v-html="asset.description" />

      <div ref="text" class="Asset__link" v-if="asset.type == 'video'" >
        <a target="_blank" :href="asset.url_public">
          {{ trans('btn') }}
          <!-- <img src="@/components/asset/img/database.png" /> -->
        </a>
      </div>

    </template>
    <Carroussel :assets="asset" v-else />
  </div>
</template>

<script>
import Carroussel from '@/components/carroussel/Carroussel'

export default {
  name: 'Asset',
  props: [ 'asset', 'index' ],
  components: { Carroussel },
  translations: {
    en: { btn: 'See database entry' },
    fr: { btn: 'Voir la fiche' },
  },
  created() {
    // if(!Array.isArray(this.asset)) {
    //   this.asset.html += `<a href="http://google.com">google</a>`
    //   this.asset.html += `<a href="http://technes.site/omekas/s/public/media/76">fiche</a>`
    // }
  },
  mounted() {
    if(!this.asset) return
    if(this.$refs.text) {
      this.$nextTick(() => {

        let updates = []

        _.each(this.$refs.text.querySelectorAll('a'), (link, index) => {

          console.log(link);
          console.log(link.href)
          console.log(link.href.startsWith('technes://'));
          console.log(link.href.search('encyclo-technes.org/fr/base'));
          console.log('xxxxxxxxxxxxx == ');

          if(this.isExternal(link.href) 
          && !link.href.startsWith('technes://')
          && link.href.search('encyclo-technes.org/fr/parcours') == -1
          && link.href.search('encyclo-technes.org/en/parcours') == -1
          || link.href.search('encyclo-technes.org/fr/base') > -1 
          || link.href.search('encyclo-technes.org/fr/base') > -1 
          ) {

            // link.classList.add('is-external')
            // link.target = '_blank'
            // if(link.href.search('encyclo-technes.org/fr/base') > -1 
            // || link.href.search('encyclo-technes.org/en/base') > -1)
            //   link.classList.add('is-database')

            const suffixe = this.$root.is_firefox ? '-firefox' : '-firefox'

            link.target = '_blank'
            if(link.href.search('encyclo-technes.org/fr/base') > -1 
            || link.href.search('encyclo-technes.org/en/base') > -1) 
            {
            // || link.href.search('encyclo-technes.org/fr/parcours') > -1
            // || link.href.search('encyclo-technes.org/en/parcours') > -1) 
            // {

              link.classList.add('is-database'+ suffixe)

              // if(this.$root.is_firefox) 
              //   link.classList.add('is-database-firefox')
              // else
              //   link.classList.add('is-database')
            }
            else {

              link.classList.add('is-external'+ suffixe)

              // if(this.$root.is_firefox) 
              //   link.classList.add('is-external-firefox')
              // else
              //   link.classList.add('is-external')
            }
          }


          if(link.href.startsWith('technes://')) {

            if(this.$route.meta.name == 'pdf') return

            const hiddenChapters = this.$parent.$parent.$parent.chapters.hidden
            const showedChapters = this.$parent.$parent.$parent.chapters.visible
            const slug = link.href.replace('technes://', '')

            let chap = _.find(hiddenChapters, c => c.slug == slug)
            let isVisible = false
            if(!chap) {
              chap = _.find(showedChapters, c => c.slug == slug)
              isVisible = true
            }

            if(chap) {
              if(!isVisible) {
                link.target = '_blank'
                const res = this.$router.resolve({
                  name: 'single.' + this.lang,
                  params: {
                    parcours: this.$route.params.parcours,
                    slug: slug,
                    id: chap.id
                  }
                })
                link.href = res.href

              } else {
                const indexChap = _.findIndex(showedChapters, c => c.slug == slug) + 1

                // link.href.replace('technes://', 'xxx')
                
               
                // console.log(link.href);
                // // console.log(indexChap);
                // // console.log(link);

                // console.log(index);

                updates.push(index)

                // this.$refs.text.querySelectorAll('a')[index].href = '#'+ link.href
                // console.log(this.$refs.text.querySelectorAll('a')[index]);

                link.addEventListener('click', () => {
                  if(this.$parent.$parent.$parent.is_linear)
                    TweenMax.to('.Parcours', 1, { scrollTo: {
                      y: '.Chapter.is-' + chap.id,
                      offsetY: 300
                    }})
                  else
                    this.$router.push({ params: { chapter: indexChap } })
                })

              }
            }
            else 
              link.classList.add('is-disable')
          }


          if(link.getAttribute('href') && link.getAttribute('href').startsWith('#')) {
            if(this.$route.meta.name == 'pdf') return
            const href = link.getAttribute('href')
            const name = href.replace('#', '')
            const start = link
            const end = this.$refs.text.querySelector('a[name$="' + name + '"]')
            const newName = 'technes_' + this.$parent.$parent.index + '_' + this.index + '_' + index

            start.href = '#' + newName
            end.name = newName
            end.classList.add('anchor')
            if(this.$parent.$parent.$parent.is_linear)
              end.classList.add('is-linear')
          }

          if(this.$root.is_android)
            link.classList.add('is-android')

        })

        _.each(updates, (index)=>{
          let link = this.$refs.text.querySelectorAll('a')[index].href
          this.$refs.text.querySelectorAll('a')[index].href = '#'+ link
          console.log(this.$refs.text.querySelectorAll('a')[index].href);
        })


      })
    }
    TweenMax.from(this.$el, .5, { opacity: 0, delay: .1 })
  },
  methods: {
    isExternal(url) {
      var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
      if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) return true;
      if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[location.protocol]+")?$"), "") !== location.host) return true;
      return false;
    }
  }
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Asset
  display block

  .resp-iframe-wrapper-235
    padding-top 42%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-1x1
    padding-top 100%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-6x5
    padding-top 83%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-4x3
    padding-top 75%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-11x8
    padding-top 73.25%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-143
    padding-top 70.25%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-16x10
    padding-top 62.5%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-5x3
    padding-top 60%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-185
    padding-top 54%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-2x1
    padding-top 50%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-22x1
    padding-top 47%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-235
  .resp-iframe-wrapper-239
    padding-top 43%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-259
    padding-top 39%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-276 
    padding-top 37%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-16x9
    padding-top 56.25%
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  .resp-iframe-wrapper-son 
    padding-top 30px
    position relative
    iframe
      position absolute
      height 100%
      width 100%

  p.is-video
    margin 64px 0 0
    position relative
    width 100%
    padding-top 56.5%

  &__link
    font-size 10px
    line-height 18px
    text-align center
    display block
    margin-bottom 32px
    margin-top 10px

    img
      margin-left 6px
      width 12px
      fill none
      stroke-miterlimit 10
      stroke blue
      position relative
      top 3px

  a.anchor
    display inline-block
    position relative
    top -440px
    visibility hidden
    &.is-linear
      top -300px

    +below(portrait)
      display inline-flex


  a.disable
    pointer-events none

  a.is-external
    display inline-block
    padding-right 20px
    position relative
    &:after
      position absolute
      top initial
      right 5px
      width 10px
      height 20px
      content ''
      background-image url('./img/arrow.png')
      background-size contain
      background-repeat no-repeat
      display inline-flex

    +below(portrait)
      display inline

  a.is-database
    display inline-block
    padding-right 20px
    position relative
    &:after
      background-image url('./img/database.png')
      background-size contain
      background-repeat no-repeat
      position absolute
      top initial
      right 0px
      width 12px
      height 20px
      content ''
      display inline-flex

    +below(portrait)
      display inline


  a.is-external-firefox
    display inline-block
    padding-right 0px
    position relative
    &:after
      // position absolute 
      width 10px
      height 13px
      margin-left 3px
      margin-top 2px
      content ''
      background-image url('./img/arrow.png')
      background-size contain
      background-repeat no-repeat
      display inline-flex

    +below(portrait)
      display inline

  a.is-database-firefox
    display inline-block
    padding-right 0px
    position relative
    &:after
      // position absolute 
      width 10px
      height 13px
      margin-left 3px
      margin-top 2px
      content ''
      background-image url('./img/database.png')
      background-size contain
      background-repeat no-repeat
      display inline-flex

    +below(portrait)
      display inline

  a
    color blue
    overflow-wrap break-word

  a.is-disable 
    color black
    pointer-events none

  cite
    font-size 11px
    & p
      margin 0
    &.hr 
      color blue

  strong
  b
    font-family "IBM Plex Sans"
    font-weight 700
    font-style normal

  h1
    font-size 26px
    line-height 34px

  h2
    font-size 22px
    line-height 30px

  h3
    font-size 18px
    line-height 26px

  h4
    font-size 16px
    line-height 24px

  i
  em
    font-style oblique

  sup
    font-size smaller

  hr 
    border none 
    background #1f95ff
    height 1px
    margin 88px 0 44px

  &__creator
    font-size 12px
    margin 20px 0

  &__description
    font-size 12px
    text-align center
    padding 12px 0 0 0

  p > cite 
    line-height 18px

  table
    text-align left
    font-size 12px
    line-height 18px
    font-weight 500

    th 
      border 1px solid #afafaf
      padding 8px 16px

    td
      border-left 1px solid #afafaf
      border-right 1px solid #afafaf
      padding 16px

    tr:last-child
      border-bottom 1px solid #afafaf

    tr:nth-child(odd) 
      background rgba(blue, .1)

    tr:first-child
      background transparent

  & aside

    & p 
      line-height 13px

    &.notes-bloc 
      margin-left 52px
      margin-bottom 24px

      +below(portrait)
        margin-left 24px

  & .notes-hr
    color #1f95ff
    background transparent
    font-size 12px

</style>
