<template>
  <div class="Chapter__master">
      <div class="Chapter" ref="chapter" v-if="chapter" :class="['is-'+ chapter.id, { 'is-introduction': isIntro }]">
        <div class="Chapter__index" v-if="index !== null && is_linear == true">{{ index+1 }}</div>
        <div class="Chapter__title">{{ chapter.title }}</div>
        <template v-if="chapter.medias.length > 0">
          <lazy-component v-if="$route.meta.name != 'pdf'" @show="loadAssets">
            <Asset :asset="asset" :key="index" :index="index" v-for="(asset, index) in assets" />
          </lazy-component>
          <template v-else >
            <Asset v-if="asset.type != 'video'" :asset="asset" :key="index" :index="index" v-for="(asset, index) in assets" />
          </template>
        </template>
        <div>

          <template v-if="chapter.medias.length != count">

            <div class="Chapter__loading" >
              <div>
                <div>{{ trans('loading') }} {{ count }} / {{ chapter.medias.length }}</div>
                <div class="Chapter__spin" />
              </div>
            </div>

          <ContentLoader :speed="0" primaryColor="#c7c3b8">
                  <rect x="0" y="0" rx="3" ry="3" :width="width*.45" height="10" />
                  <rect x="0" y="20" rx="3" ry="3" :width="width*.35" height="10" />
                  <rect x="0" y="40" rx="3" ry="3" :width="width*.30" height="10" />
                  <rect x="0" y="60" rx="3" ry="3" :width="width*.25" height="10" />
                  <rect x="0" y="60" rx="3" ry="3" :width="width*.35" height="10" />
                  <rect x="0" y="80" rx="3" ry="3" :width="width*.35" height="10" />
                  <rect x="0" y="100" rx="3" ry="3" :width="width*.30" height="10" />
                  <rect x="0" y="120" rx="3" ry="3" :width="width*.25" height="10" />
            </ContentLoader>
            <div class="Chapter__loading" >
             

            </div>
            <ContentLoader :speed="0" primaryColor="#c7c3b8">
                  <rect x="0" y="0" rx="3" ry="3" :width="width*.45" height="10" />
                  <rect x="0" y="20" rx="3" ry="3" :width="width*.35" height="10" />
                  <rect x="0" y="40" rx="3" ry="3" :width="width*.30" height="10" />
                  <rect x="0" y="60" rx="3" ry="3" :width="width*.25" height="10" />
                  <rect x="0" y="60" rx="3" ry="3" :width="width*.35" height="10" />
                  <rect x="0" y="80" rx="3" ry="3" :width="width*.35" height="10" />
                  <rect x="0" y="100" rx="3" ry="3" :width="width*.30" height="10" />
                  <rect x="0" y="120" rx="3" ry="3" :width="width*.25" height="10" />
            </ContentLoader>
            <div class="Chapter__space"></div>
          </template>
        </div>

        <!-- <a href="https://papyrus.bib.umontreal.ca/xmlui/bitstream/handle/1866/22678/gunning_tom_2011_shooting_into_outer_space.pdf?sequence=1&amp;isAllowed=y#page=6">example</a> -->

       <!--  <ul>
          <li>Internet Explorer</li>
          <li>Mozilla Firefox</li>
          <li>Safari</li>
          <li>Opera</li>
          <li>Google Chrome</li>

            <ul>
              <li>Google Chrome</li>
              <ul>
              <li>Google Chrome</li>
            </ul>
            </ul>


          <li>Internet Explorer</li>
          <li>Mozilla Firefox</li>
          <li>Safari</li>
          <li>Opera</li>
        </ul> -->
  

       
        <!--   
        <tbody>
          <th>Colonne 1</th>
          <th>Colonne 2</th>
          <th>Colonne 3</th>
        </tbody>
        
        <tbody>
          <tr>
            <th>Company</th>
            <th>Contact</th>
            <th>Country</th>
          </tr>
          <tr>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
          </tr>
          <tr>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
          </tr>
          <tr>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque turpis tellus, luctus ut metus vitae, commodo dignissim leo. Curabitur eget placerat turpis. Vestibulum nunc ipsum, accumsan sed ultrices a, tempor ac ex.</td>
          </tr>
        </tbody> 
        -->
       

      </div>
  </div>
</template>

<script>
import ScrollMagic from 'scrollmagic'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
import Asset from '@/components/asset/Asset'
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'Chapter',
  props: [ 'chapter', 'is_linear', 'index', 'isIntro' ],
  components: { Asset, ContentLoader },
  beforeDestroy() {
    if(this.$magic) this.removeMagic()
  },
  data: () => ({
    assets: [],
    count: 0,
    width: 0
  }),
  translations: {
    en: {
      loading: 'Loading',
    },
    fr: {
      loading: 'Chargement'
    },
  },

  created() {
    this.width = window.innerWidth/2
  },

  mounted: async function() {
    this.$nextTick(() => {
      !this.is_linear
      ? TweenMax.from(this.$el, 0.5, { alpha: 0, ease: Linear.easeNone, delay: 0.25 })
      : this.addMagic()
    })

    if(this.$route.meta.name == 'pdf')
      this.loadAssets()
  },
  methods: {

    loadAssets: async function() {
      const assets = []
      while(this.count < this.chapter.medias.length) {
        let asset = await this.getAsset(this.chapter.medias[this.count]) 
      
        // if(asset.type == 'video') {
        //   console.log(this.count +' | '+ this.chapter.medias.length, asset.type);
        //   console.log(asset.html);
        //   console.log('xxxxxxxxxxxxxx');
        // }

        if(asset.html.indexOf('<div class="resp-iframe-wrapper-16x9 pdf">') != -1 && this.$root.isPhone
        || asset.html.indexOf('<div class="resp-iframe-wrapper-16x9 pdf">') != -1 && this.$root.isTablet){
          const res = asset.html.split('src=')[1].split('><')[0].split(' ')
          asset.html = ''
          _.each(res,(link)=>{
            if(link.indexOf('.pdf') != -1)
              asset.html = '<div><a href="'+ link +'" target="_blank">'+ link +'</a></div>'
          })
        }

        // if(this.count == 2
        // || this.count == 3 
        // || this.count == 4
        // || this.count == 5
        // || this.count == 6
        // || this.count == 7
        // || this.count == 8
        // || this.count == 9
        // || this.count == 10
        // || this.count == 11
        // || this.count == 12
        // || this.count == 13
        // || this.count == 14
        // || this.count == 15
        // // || this.count == 16
        // || this.count == 17
        // || this.count == 18
        // || this.count == 19
        // || this.count == 20
        // || this.count == 21
        // || this.count == 22

        // )

        assets.push(asset)
        this.count++
      }
      this.groupMedias(assets)

      if(this.$scene)
        this.$nextTick(() => this.$scene.duration(this.$el.offsetHeight))

      this.$emit('loaded')
    },
    groupMedias(assets) {
      let count = 0
      while(count < assets.length) {
        let asset = assets[count]
        let startGroupImage = false
        if(asset.type == 'image') {
          startGroupImage = true
          const caroussel = []
          while(count < assets.length && startGroupImage) {
            asset = assets[count]
            if(asset.type == 'image') { caroussel.push(asset); count++ }
            if(asset.type != 'image') startGroupImage = false
          }
          this.assets.push(caroussel)
        } else {
          this.assets.push(asset)
          count++
        }
      }
    },
    addMagic() {
      this.$magic = new ScrollMagic.Controller()
      this.$scene = new ScrollMagic.Scene({
          triggerElement: this.$el,
          offset: 180,
          duration: this.$el.offsetHeight
      })
      // .addIndicators()
      .on("enter", (evt)=> {
        this.$router.replace({ params: { chapter: this.chapter.order }})
      })
      .on("leave", (evt)=> { this.$router.replace({ params: { chapter: this.chapter.order }}) })
      .addTo(this.$magic)
    },
    removeMagic() {
      if(this.$magic){
          this.$magic.destroy(true)
          this.$magic = null
      }
    },
  },
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Chapter
  position relative
  padding 24px

  &.is-introduction
    border-left 2px solid #afafaf
    padding 0
    padding-left 25px
    margin 25px
    a.is-external
      &:after
        display none
    .Chapter__title
      display none

  +below(portrait)
    margin 24px 0

  small
    font-size 12px

  &__space 
    height 48px

  &__spin
    border 2px solid rgba(grey-dark, .5)
    border-top 2px solid rgba(grey-dark, .1)
    border-radius 50%
    width 50px
    height 50px
    animation Chapter__spin 2s linear infinite
    margin 20px auto
    @keyframes Chapter__spin {
      from { transform: rotate(0deg) }
      to { transform: rotate(360deg) }
    }

  &__loading
    min-height 40vh
    text-align center
    font-size 16px
    color grey-dark
    display flex
    align-items center
    justify-content center

  &__trigger
    position absolute
    height 100%

  &__master
    min-height 20vh

  &__title
    font-size 32px
    line-height 38px !important
    padding-bottom 32px
    font-family 'Libre Baskerville', serif

    +below(tablet)
      font-size 28px
      line-height 30px

  &__index
      font-family 'IBM Plex Sans', sans-serif
      font-size 16px
      padding-bottom 32px
      color #1f95ff

  &.is-1
      background #d8d6c3
  &.is-2
      background #dbdaca
  &.is-3
      background #e1e0d0
  &.is-4
      background #e6e6d7
  &.is-5
      background #ecebdf
  &.is-6
      background #f1efe5
  &.is-7
      background #f6f4ea
  &.is-8
      background #faf8ef

  .is-image
      text-align center

  iframe
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      width 100% !important
      height 100% !important

  a.anchor
    display inline-block
    position relative
    top -440px
    visibility hidden
    &.is-linear
      top -300px

    +below(portrait)
      display inline-flex


  a.disable
    pointer-events none

  a.is-external
    display inline-block
    position relative
    &:after
      position absolute
      top initial
      right 5px
      width 10px
      height 20px
      content ''

    +below(portrait)
      display inline

  a
    color blue
    line-height 16px
    display inline-block
    overflow-wrap break-word

    +below(portrait)
      display inline

  cite
    font-size 11px
    & p
      margin 0
    &.hr 
      color blue

  p > cite 
    line-height 16px

  strong
  b
    font-family "IBM Plex Sans"
    font-weight 700
    font-style normal

  h1
    font-size 26px
    line-height 34px

  h2
    font-size 22px
    line-height 30px

  h3
    font-size 18px
    line-height 26px

  h4
    font-size 16px
    line-height 24px

  h2
  h3
  h4
  h5
  h6
    padding 8px 0

  i
  em
    font-style oblique

    & a.is-android
      display inline-block

  sup
    vertical-align 5px
    font-size smaller
    line-height 0

  &__creator
    font-size 12px
    margin 20px 0

  &__description
    font-size 10px
    margin 20px 0
    background white
    padding 10px


  table
    text-align left
    font-size 12px
    line-height 18px
    font-weight 500

    th 
      border 1px solid #afafaf
      padding 8px 16px

    td
      border-left 1px solid #afafaf
      border-right 1px solid #afafaf
      padding 16px

    tr:last-child
      border-bottom 1px solid #afafaf

    tr:nth-child(odd) 
      background rgba(blue, .1)

    tr:first-child
      background transparent

</style>



















