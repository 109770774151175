<template>
  <div class="Search" :class="'is-'+ active">
    <div class="Search__container">

      <div class="Search__header">

        <div class="Navigation__container Flex__row-center-space-between Search__navigation">
          <div class="Navigation__container__logo Flex__row-start" @click="onClick('logo')">
            <svg class="Navigation__logo" viewBox="0 0 27.51 26.91">
              <rect class="cls-1" width="24.92" height="7.36"/>
              <rect class="cls-1" x="8.4" width="8.13" height="24.92"/>
            </svg>
            <div class="Navigation__title is-black">ENCYCLOPÉDIE RAISONNÉE</br>DES TECHNIQUES DU CINÉMA</div>
          </div>
          <div class="Search__close Flex__row-start" @click="onClick('close')">
            <!-- <div>Fermer</div>  -->
            <svg viewBox="0 0 12.21 12.21" class="Search__svg">
              <line class="cls-2" x1="11.85" y1="11.85" x2="0.35" y2="0.35"/>
              <line class="cls-2" x1="11.85" y1="0.35" x2="0.35" y2="11.85"/>
            </svg>
          </div>
        </div>

        <div class="Search__controls Flex__column-start-center">
          <iframe
						ref="iframe"
						class="Search__iframe"
						:src="searchUrl"
					/>
        </div>

      </div>

      <transition @enter="enterAdvanced" @leave="leaveAdvanced" mode="out-in">
        <div class="Search__container-advanced" v-if="search=='advanced'">
          <SearchAdvanced id="multicategories" setting="open" zindex="9550"/>
          <SearchAdvanced id="sources" setting="close" zindex="9540"/>
          <SearchAdvanced id="contenu_textuels" setting="close" zindex="9530"/>
          <SearchAdvanced id="appareil" setting="close" zindex="9520"/>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

// on themes/default/view/layout/layout.phtml in the header
// 		if(window.location !== window.parent.location) {
// 				var style = document.createElement('style')
// 				style.innerHTML = `
// 						html { background: #fffdf3 !important }
// 						body { background: #fffdf3 !important }
// 						#user-bar { display: none !important }
// 						header { display: none !important }
// 						footer { display: none !important }
// 						.advanced-search { display: none !important }
// 				`
// 				document.head.appendChild(style)
// 		}

import SearchAdvanced from './SearchAdvanced'

export default {

  name: 'Search',
  components: { SearchAdvanced },

  data: () => ({
    active: 'disable',
    search: 'regular',
    name: "",
  }),
  created() {
  },
  methods: {
    enterAdvanced(el, done) {
      TweenMax.to(el, .5, { className: '+=is-active', onComplete: done })
    },
    leaveAdvanced(el, done) {
      TweenMax.to(el, .5, { className: '-=is-active', onComplete: done })
    },
    reset() {
      this.name = ''
      this.search = 'regular'
    },
    getClass(arg) {
      if(arg === 'advanced' && this.search === 'regular' || arg === 'regular' && this.search === 'advanced')
        return 'is-disable'
      else
        return ''
    },
    onClick(val) {
      switch(val) {
        case 'logo':
          break
        case 'close':
          this.$store.commit('app/TOOGLE_SEARCH')
          break
        case 'regular':
          this.search = 'regular'
          break
        case 'advanced':
          this.search = 'advanced'
          break
      }
    },
  },
  watch: {
    toogle_search(nw, od) {
      if(nw) this.active = 'enable'
      else {
        this.active = 'disable'
        TweenMax.delayedCall(1, this.reset)
      }
    },
    '$route': {
      immediate: true,
      handler() {
        if(this.active === 'enable')
           this.$store.commit('app/TOOGLE_SEARCH')
      }
    },
    name(nw, od) {
      const size = _.size(nw)
      if(size>6) {
        const sz = window.innerWidth
        TweenMax.to(this.$refs.name, 1.2, { width: sz, ease: Cubic.easeOut })
      } else {
         TweenMax.to(this.$refs.name, .6, { width: 260, ease: Cubic.easeOut })
      }
    },
  },
  computed: {
    searchUrl() {
      if(process.env.NODE_ENV != 'production')
        return this.searchUrl = 'http://technes.demotte.net/themes/origin/technes-search-en.php'
      
      return this.searchUrl = '/themes/origin/technes-search-' + this.lang + '.php'
    },
    toogle_search() { return this.$store.getters['app/toogle_search'] },
  }
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Search
  position fixed
  width 100vw
  height 100%
  z-index 8000
  background rgb(0,0,0)
  background linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)
  transition opacity .5s
  overflow overlay

  &__iframe
    width 100%
    height calc(100vh - 96px)

  &.is-enable
    pointer-events auto
    opacity 1

  &.is-disable
    pointer-events none
    opacity 0

  &__container
    width 100%
    height 100%
    display flex
    flex-direction column

  &__header
    width 100vw
    background-color white

  &__container-advanced
    background-color #f1f1f1
    flex 1
    padding 66px 120px 120px 120px
    opacity 0
    transition opacity .5s

    &.is-active
      opacity 1

  &__close
    color blue
    cursor pointer
    font-size 14px
    font-weight bold
    line-height 11px

  &__controls
    // height 136px
    // padding 32px 64px 32px 108px
    transition all 2s

  &__control
    height 32px
    cursor pointer

    &.is-left
      padding-right 32px

    &.is-right
      padding-left 32px
      border-left 1px solid grey

    &.is-disable
      color grey

  &__svg
    width 13px
    padding-left 12px

  &__svg-advanced
    width 20px
    padding-right 8px

  &__input-container
    height 100px

  &__button
    color blue
    border 1px solid blue
    border-radius 12px
    height 32px
    min-width 50px
    text-align center
    line-height 33px
    margin-left 32px
    font-size 14px
    cursor pointer

  &__input
    font-family 'Libre Baskerville', serif
    font-size 30px
    border none
    color black
    padding 8px 0
    width 260px
    background none

  &__input::placeholder
    color grey

  .cls-1
    transition fill 1s ease
    fill black

  .cls-2
    fill none
    stroke blue
    stroke-miterlimit 10

  .Navigation__container
    height 96px

    +below(tablet)
      height 25px

  .cls-icon
    fill none
    stroke #000
    stroke-miterlimit 10
    transition stroke 1s ease

    &.is-disable
      stroke grey

</style>



