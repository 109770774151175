<template>
  <div class="Page About">
    <div class="About__container">
      <div class="About__left">
        <div class="Chapter">
          <div class="Chapter__title">{{ page.admin[lang].title }}</div>
          <p v-html="page[lang].text" />
        </div>
      </div> 
      <div class="About__right">
        <img :src="page.cover.sizes.large" />
      </div>
    </div>
  </div>
</template>

<script>

import Page from '@/core/mixins/Page'
import Settings from '@/config/Settings'

export default {
  name: 'About',
  mixins: [ Page ],
  translations: {
    en: { title: 'Encyclopedia of Film Techniques and Technologies, TECHNÈS', subtitle: 'About' },
    fr: { title: 'Encyclopédie raisonnée des techniques du cinéma TECHNÈS', subtitle: 'À propos' },
  },
  head() {
    return {
      title: this.trans('title'),
      titleTemplate: '%s - '+ this.trans('subtitle'),
    }
  },
  mounted() {
    this.$tempo = Settings.tempo
  },
  methods: {
    enter(done) {
      const promise = this.$parent.$refs.front.hide()
      promise.then(() => {
        done()
      })
    },
    leave(done) {
      const promise = this.$parent.$refs.front.show()
      promise.then(() => {
        done()
      })
    },
  },
}

</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.About
  padding-top 200px
  width 100vw

  +below(tablet)
    padding-top 90px

  &__container
    max-width 1000px
    display flex
    margin 0 auto

    +below(portrait)
      align-items flex-start
      flex-direction column

  &__left
    width 65%
    +below(portrait)
      width 100vw

    .Chapter
      padding 0
      margin 0
      +below(tablet)
        padding 32px

  &__right
    width 35%
    padding-left 100px
    padding-top 70px

    +below(tablet)
      padding 104px 54px 0 0

    +below(portrait)
      width 100vw
      margin 0
      padding 0

    & img
      margin 0 32px 32px
      width calc(100vw - 64px)

</style>

















