<template>
  <div class="Landscape">
    <transition name="Landscape__content">
      <div class="Landscape__content" v-if="$root.orientation == 'landscape'">
        <div class="Landscape__container">
          <img class="Landscape__ico" :src="require('./img/portrait.png')" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Landscape',
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'

.Landscape

  &__content
    position fixed
    width 100vw
    height 100%
    top 0px
    left 0px
    transition opacity .25s
    color white
    z-index 9999999
    background black
    &-enter
    &-leave-active
      opacity 0

  &__container
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    width 100%
    text-align center

  &__ico
    width 130px
    height 130px

</style>
