<template>
  <div class="SearcAdvanced" :style="{ zIndex: zindex }">
    <div class="Search__button" :class="'is-'+ context " @click="onClick()">{{ id }}</div>
    <div class="SearcAdvanced__container" :id="'container_'+ id" v-if="array.length > 0">
      <RowComp :key="i" v-for="(obj, i) in array" :obj="obj" :pos="i"/>
    </div>
    <div class="SearcAdvanced__container" :id="'container_'+ id" v-if="blocks.length > 0">
      <BlockComp :key="i" v-for="(obj, i) in blocks" :obj="obj" :pos="i"/>
    </div>
  </div>
</template>

<script>

import { Engine } from './js/engine.js'
import RowComp from './RowComp'
import BlockComp from './BlockComp'

export default {

  name: 'SearcAdvanced',
  components: { RowComp, BlockComp },
  props: ['id', 'setting', 'zindex'],

  data: () => ({
    context: 'open',
    array: [],
    blocks: []
  }),

  created() {

    this.context = this.setting
    _.each(Engine[this.id], (row)=>{
      if(row.blocks == undefined) this.array.push(row)
      else this.blocks.push(row)
    })
  },

  mounted() {

    if(this.setting === 'open') {
      TweenMax.set('#container_'+ this.id, { className: '-=is-active' })
      this.hiddenContainer(false)
    }
    else TweenMax.set('#container_'+ this.id, { className: '+=is-active' })
  },

  methods: {

    onClick() {
      if(this.context === 'open') this.close()
      else this.open()
    },

    open() {
      this.context = 'open'
      TweenMax.to('#container_'+ this.id, .25, { className: '-=is-active', onComplete: ()=>{
        this.hiddenContainer(false)
      } })
    },

    close() {
      this.context = 'close'
      this.hiddenContainer(true)
      TweenMax.to('#container_'+ this.id, .25, { className: '+=is-active' })
    },

    hiddenContainer(b) {
      if(b) TweenMax.set('#container_'+ this.id, { overflow: 'hidden' })
      else TweenMax.set('#container_'+ this.id, { overflow: 'initial' })
    },

  },

}

</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.SearcAdvanced
  position relative
  display flex
  width 100%
  height auto
  margin-top 54px

  .Search__button
    position absolute
    margin-left 12px
    top -26px
    padding 0 20px

    &.is-open
      background-color blue
      color white

    &.is-close
      background-color #f1f1f1
      color blue

  &__container
    width 100%
    background-color white
    overflow hidden

    &.is-active
      height 0px

  .Search__button
    z-index 2000


</style>



