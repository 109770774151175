const Engine = {

  multicategories: [
    { 
      conditions: [],
      labels: [ 'title' ],
      type: 'title'
    },
    { 
      conditions: [ 'and', 'or', 'without' ],
      labels: [ 'maker', 'trademark', 'model' ],
      type: 'topic'
    },
    { 
      conditions: [ 'and', 'or', 'without' ],
      labels: [ 'creator', 'author', 'contributor' ],
      type: 'topic'
    },
    { 
      conditions: [ 'before', 'after' ],
      type: 'date'
    },
  ],

  sources: [
    { 
      conditions: [],
      labels: [ 'title' ],
      type: 'title'
    },
    { 
      conditions: [ 'and', 'or', 'without' ],
      labels: [ 'creator', 'contributor' ],
      type: 'topic'
    },
    { 
      conditions: [ 'and', 'or', 'without' ],
      labels: [ 'editor' ],
      type: 'topic'
    },
    { 
      conditions: [ 'before', 'after', 'interval' ],
      type: 'date'
    },
    {    
      column1: {
        title: 'lang',
        type: 'list',
        content : [
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
        ]
      },
      column2: {
        title: 'document',
        type: 'list',
        content : [
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
        ]
      } 
    }
  ],

  contenu_textuels: [
    { 
      conditions: [],
      labels: [ 'title' ],
      type: 'title'
    },
    { 
      conditions: [ 'and', 'or', 'without' ],
      labels: [ 'responsability' ],
      type: 'topic'
    },
    { 
      conditions: [ 'and', 'or', 'without' ],
      labels: [ 'author' ],
      type: 'topic'
    },
    {    
      column1: {
        title: 'lang',
        type: 'list',
        content : [
          { type: 'checkbox', title: 'lang' },
          { type: 'checkbox', title: 'lang' },
        ]
      }
    }
  ],

  appareil: [

    {
      title: 'metadonnees_generales',
      blocks: [
        {
          title : 'identification',
          content: [
            { 
              conditions: [],
              labels: [ 'title' ],
              type: 'title'
            },
            { 
              conditions: [ 'and', 'or', 'without' ],
              labels: [ 'maker', 'trademark', 'model' ],
              type: 'topic'
            }, 
            {    
              column1: {
                title: 'type_appareil',
                type: 'list',
                content : [
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                ]
              },
              column2: {
                title: 'function',
                type: 'list',
                content : [
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                ]
              },
              column3: {
                title: 'document_type',
                type: 'list',
                content : [
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                ]
              },
              column4: {
                title: 'practice',
                type: 'list',
                content : [
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                ]
              } 
            }

          ]
        },
        {
          title : 'fabrication',
          content: [
            { 
              conditions: [],
              labels: [ 'lieu' ],
              type: 'title'
            },
            { 
              conditions: [ 'before', 'after' ],
              type: 'date'
            },
          ]
        },



        {
          title : 'caracteristiques_physiques',
          content: [
            
            {    
              column1: {
                title: 'materiaux',
                type: 'list',
                content : [
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                  { type: 'checkbox', title: 'lorem' },
                ]
              },
              column2: {
                title: 'portable',
                type: 'list',
                content : [
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                ]
              },
              column3: {
                title: 'son_synchrone',
                type: 'list',
                content : [
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                  { type: 'radio', title: 'lorem' },
                ]
              }
            }


          ]
        }
      ]
    },


    {
      title: 'camera',
      blocks: [
        {
          title : 'camera_identification',
          content: [
            { 
              conditions: [],
              labels: [ 'title' ],
              type: 'title'
            },

          ]
        }
      ]
    },





  ],


}
export { Engine }






















