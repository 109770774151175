<template>
  <div ref="container" class="Thumbnail" :class="'is-' + id">
    <div class="Thumbnail__categories">
      <router-link :to="{ name: 'home.' + lang, params: { categorie: cat }}" :key="cat" class="Thumbnail__categorie" v-for="cat in parcour.categories">
        {{ cat }}
      </router-link>
    </div>
    <div class="Thumbnail__container" @click="onClick()">
      <div class="Thumbnail__cover" :style="{ backgroundImage: 'url(' + parcour.cover + ')' }" />
      <div class="Thumbnail__button" v-if="$root.above('portrait')">{{ trans('consult') }}</div>
      <div class="Thumbnail__text">
        <div class="Thumbnail__title">{{ parcour.title }}</div>
        <div class="Page__header-sub">{{ parcour.author.parcours }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  name: 'Thumbnail',
  props: ['id', 'type'],
  data: ()=>({
    delay : 0,
    is_promotion: false
  }),
  translations: {
    en: { consult: 'Explore' },
    fr: { consult: 'Consulter' }
  },
  mounted: async function() {
    this.$locked = false
    this.delay = (this.type === 'left') ? 0 : 250
    AOS.init({ duration: 500 })
  },
  beforeDestroy() {},
  methods: {
    onClick() {
      this.$store.commit('app/SET_PARCOURS', 'thumbnail')
      this.$router.push({ params: { parcours: this.id, chapter: null }})
    },
    reset() {
      this.$locked = false
      TweenMax.set(this.$el, { opacity: 1 })
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          if(this.is_promotion && this.$locked)
            this.reset()
          else if(this.$route.params.parcours === this.id) {
            TweenMax.set(this.$el, { opacity: 0, delay: 0.2 })
            this.$locked = true
          }
        })
      }
    },
    reverse_completed(nw, od) {
      if(this.$locked) 
        this.reset()
    },
    parcours(nw, od) {
      if(nw === 'explore') {
          this.$locked = false
          TweenMax.set(this.$el, { opacity: 1 })
      } 
      else if(nw === 'promotion') 
        this.is_promotion = true
    }
  },
  computed: {
    parcour() { return _.find(this.parcours_data, p => p.id == this.id) },
    parcours_data() { return this.$store.getters['data/parcours'] },
    parcours() { return this.$store.getters['app/parcours'] },
    reverse_completed() { return this.$store.getters['app/reverse_completed'] },
  }
}
</script>

<style lang="stylus">

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Thumbnail
  position relative
  background grey
  cursor pointer
  float left
  max-width 520px
  max-height 332px
  width 49%
  // height calc(520*0.62)
  height 332px
  margin 0 0 32px 0

  +below(ipad)
    width 100vw
    height calc(100vw*0.62)
    max-height initial
    max-width initial
    margin 16px

  &__container
    background-color black
    position relative
    overflow hidden
    width 100%
    height 100%

  &__cover
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    transition opacity 0.4s
    background-size cover
    background-position top center
    opacity .8

  &__text
    color white
    width 100%
    height 90%
    transition opacity 0.8s
    position relative
    display flex
    align-items flex-end
    justify-content space-between

    +below(tablet)
      flex-direction column
      align-items flex-start
      justify-content flex-end

      & .Page__header-sub 
        max-width auto !important
        width 220px !important
        margin-left 32px
        margin-top 16px
        display flex
        align-items center
        padding-left 12px

  &__title
    font-size 16px
    line-height 20px
    font-family 'Libre Baskerville', serif
    max-width 200px
    padding-left 32px

  &__container:hover
    .Thumbnail__cover
      opacity 0.25
    .Thumbnail__button
      opacity 1

  &__button
    position absolute
    color blue
    border 1px solid
    border-radius 4px
    padding 8px
    min-width 80px
    text-align center
    top 50%
    left 50%
    transform translate(-50%, -50%)
    transition opacity 0.6s
    opacity 0

  &__categories
    display flex
    flex-direction rows
    position absolute
    top -12px
    left 24px
    z-index 5

    +below(tablet)
      width 272px
      display block

  &__categorie
    background-color #44fcdd
    color black
    padding 8px 16px
    min-width 80px
    text-align center
    font-size 11px
    margin-right 16px

    +below(tablet)
      display inline-flex
      min-width auto
      padding 6px 10px
      margin-right 10px
      margin-bottom 10px

  a.Thumbnail__categorie
    color black

  .Page__header-sub
    width 25%
    border-left 1px solid white

</style>
























