<template>
  <transition @enter="show" @leave="hide">
    <div class="Fullscreen" v-if="image" @click="onClick()">
      <div class="Fullscreen__close">
            
        <svg viewBox="0 0 14.7 14.71">
          <line class="cls-1" x1="0.35" y1="0.35" x2="14.34" y2="14.35"/>
          <line class="cls-1" x1="0.35" y1="14.35" x2="14.34" y2="0.35"/>
        </svg>

      </div>
      <div ref="container" class="Fullscreen__container">
        <div class="Fullscreen__image" v-html="image"/>
      </div>
    </div>
  </transition>
</template>

<script>

export default {

  name: 'Fullscreen',
  data: () => ({ image: null }),
  methods: { 
    onClick() { this.$store.commit('app/FULLSCREEN', null) },
    show(el, done) {
      TweenLite.from(el, .5, { alpha: 0, onComplete: done })
    },
    hide(el, done) {
      TweenLite.to(el, .5, { alpha: 0, onComplete: done })
    },
  },
  watch: { 
    fullscreen(nw, od) { this.image = nw }
  },
  computed: { 
    fullscreen() { return this.$store.getters['app/fullscreen'] }
  }
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Fullscreen
  position fixed
  top 0px
  left 0px
  right 0px
  width 100vw
  height 100vh
  z-index 9000
  background-color rgba(0,0,0,.75)
  opacity 1
  cursor pointer

  display flex
  justify-content center
  align-items center

  &__close
    position absolute
    color white
    width 16px
    right 22px
    top 22px

  .cls-1
    fill none
    stroke #ffffff
    stroke-miterlimit 10

  &__container
    max-width 80vw
    max-height 80vh
    position relative  

  &__image
    height auto
    width auto
    object-fit contain

    & img
      object-fit contain
      max-width 80vw
      max-height 80vh
   
</style>


