<template>
  <div class="Controls Flex__row-center-space-around" :class="current">
    <div class="Controls__chapter" :class="'is-'+ n" :key="n" v-for="n in $chapters.length">
      <Control :txt="$chapters[n-1].title" :id="n" :current="current" :length="$chapters.length" />
    </div>  
  </div>
</template>

<script>

import Settings from '@/config/Settings'
import Control from '@/components/parcours/Control'

export default {
  name: 'Controls',
  props: ['id', 'current'],
  components: { Control },
  created() {
    this.$chapters = this.$parent.chapters.visible
  },
  beforeDestroy() {
    if(this.parcours_from === 'promotion') 
      TweenMax.set(this.$el, { alpha: 0 })
  },
  mounted() {
    if(this.parcours_from === 'promotion') 
      TweenMax.from(this.$el, 0.5, { alpha: 0, ease: Linear.easeNone, delay: 1.75 })  
  },
  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
    parcours_from() { return this.$store.getters['app/parcours'] },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Controls
  position relative
  width 100%
  height 222px
  background white

  &.special 
    height 140px

  &__chapter
    position relative
    width 100%
    height 100%

    &.is-1
      background #d8d6c3
    &.is-2
      background #dbdaca
    &.is-3
      background #e1e0d0
    &.is-4
      background #e6e6d7
    &.is-5
      background #ecebdf
    &.is-6
      background #f1efe5
    &.is-7
      background #f6f4ea
    &.is-8
      background #faf8ef

</style>






























