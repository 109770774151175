<template>
  <div class="MobileControls"> 
    <MobileControl :key="n" v-for="n in $chapters.length" :txt="$chapters[n-1].title" :id="n"/>
    <div class="MobileControls__dropdown" :class="{ 'verysm': verysm() }" @click="useDropdown(current)"> 
      <div class="MobileControls__container" :class="['is-'+ current, { 'dropped': dropped }]"/>

      <svg viewBox="0 0 12.73 7.51" class="MobileControls__arrow" :class="[{ 'dropped': dropped }, { 'verysm': verysm() }]">
        <polyline class="cls-2" points="0.37 0.34 6.37 6.77 12.37 0.34"/>
      </svg>

    </div>
  </div>
</template>

<script>

import Settings from '@/config/Settings'
import MobileControl from '@/components/parcours/MobileControl'

export default {
  name: 'MobileControls',
  components: { MobileControl },
  data: ()=>({
    current: 0,
    used: false,
    dropped: false,
  }),
  created() {
    this.$chapters = this.$parent.chapters.visible
    this.current = (Number(this.$route.params.chapter)) ? Number(this.$route.params.chapter) : 1
  },
  beforeDestroy() {
    if(this.parcours_from === 'promotion') 
      TweenMax.set(this.$el, { alpha: 0 })
  },
  mounted() {
    if(this.parcours_from === 'promotion') 
      TweenMax.from(this.$el, 0.5, { alpha: 0, ease: Linear.easeNone, delay: 1.75 })  
  },
  methods: {
    verysm() {
      return window.innerHeight < 700
    },
    updateControl(id) {
      this.$router.push({ params: { chapter: id }})
      this.useDropdown(id)
    },
    useDropdown(id) {
      if(!this.dropped) {
        this.switchDropped()
        TweenMax.delayedCall(.2, ()=>{ this.switchUsed() })
      } else {
        this.switchUsed()
        TweenMax.delayedCall(.5, ()=>{ this.switchDropped() })
      }
    },
    switchDropped() {
      this.dropped = !this.dropped
    },
    switchUsed() {
      this.used = !this.used
      this.$store.commit('app/USE_DROPDOWN', this.used)
    },
  },
  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
    parcours_from() { return this.$store.getters['app/parcours'] }, 
  },
  
}
</script>

<style lang="stylus">

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.MobileControls
  display flex
  flex-direction column
  position relative
  width 100%
  background transparent

  &__dropdown
    width 32vw
    height 8vh
    position absolute
    top 0
    right 0
    z-index 500

    &.verysm 
      height 7vh


  &__container
    position absolute
    transition .2s ease
    right 0
    width 100%
    height 100%

    &.dropped
      right -32vw

    &.is-1
      background #d8d6c3
    &.is-2
      background #dbdaca
    &.is-3
      background #e1e0d0
    &.is-4
      background #e6e6d7
    &.is-5
      background #ecebdf
    &.is-6
      background #f1efe5
    &.is-7
      background #f6f4ea
    &.is-8
      background #faf8ef

  &__arrow
    position absolute
    width 18px
    height auto
    left 50%
    transform translateX(-50%)
    bottom 3.4vh
    transition .1s ease

    &.verysm 
      bottom 2.4vh

    &.dropped
      transform rotateZ(-90deg)
      left 83%

      +below(phone)
        left 63%

  .cls-2
    fill none
    stroke #afafaf
    stroke-miterlimit 10

  
</style>






























