<template>
  <div class="Promotion">
    <div class="Promotion__block">
      <div ref="container" class="Page__header-container Promotion__container" v-if="parcour">
        <div class="Page__header-block Page__title" v-html="parcour.title"/>
        <div class="Page__header-block Flex__row-start">
          <div class="Page__header-sub" v-if="!$root.below('tablet')" v-html="trans('title')"/>
          <div class="Page__header-sub" v-if="$root.above('portrait')" v-html="parcour.author.parcours"/>
        </div>
      </div>
      <div 
        ref="btn" class="Promotion__button" 
        @click="onClick()" 
        >
        {{ trans('consult') }}
      </div>
      <svg viewBox="0 0 12.73 7.51" class="Promotion__arrow">
        <polyline class="cls-2" points="0.37 0.34 6.37 6.77 12.37 0.34"/>
      </svg>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  data: () => ({
    parcour: null
  }),
  translations: {
    en: { consult: 'Explore', title: 'Parcours</br>ajouté récement' },
    fr: { consult: 'Consulter', title: 'Parcours</br>ajouté récement' }
  },
  created: async function() {
    this.parcour = this.parcours[this.$store.getters['data/pages'].home.promo[this.lang]]
  },
  methods: {
    onClick() {
      this.$store.commit('app/SET_PARCOURS', 'promotion')
      this.$router.push({ params: { parcours: this.parcour.id, chapter: null }})
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {}
    },
  },
  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
  }
}
</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Promotion
  color black
  display flex
  justify-content center
  align-items center
  position absolute
  bottom 0
  width 100%

  &__block
    position relative
    background-color white
    margin 0px 80px 32px 64px
    max-width 1078px
    width 100%
    height 172px

    +below(tablet)
      height 20vh
      max-height 150px
      margin 0px 16px 16px 16px

  &__container
    display flex
    justify-content center
    align-items center

    +below(tablet)
      align-items flex-start
      justify-content flex-start
      flex-direction column

      & .Page__header-block 
        padding 8px 8px 16px

  &__button
    position absolute
    bottom 24px
    right 24px
    color blue
    border 1px solid
    border-radius 4px
    padding 8px
    min-width 80px
    text-align center
    cursor pointer

    +below(portrait)
      bottom 18px
      right 20px
      font-size 12px
      padding 6px 2px

   &__arrow
    position absolute
    width 18px
    height auto
    left 50%
    transform translateX(-50%)
    bottom 2vh

  .cls-1
    fill none
    stroke blue
    stroke-miterlimit 10

  .cls-2
    fill none
    stroke blue
    stroke-miterlimit 10

  .Page
    &__title
      width 80%

</style>










