<template>
  <div class="HeaderParcours">
    <div class="Page__header-block Page__title" :class="special" v-html="parcours.title"/>
    <div class="Page__header-block Flex__row-start">
      <div class="Page__header-sub relative" v-if="parcours.is_linear">
        <Dropdown
          ref="dropdown"
          :items="chapters"
          :chapters="chapters"
          idx="parcours"
          clickOnItem="gotOnChapter"
          :scroll="$scroll" />
      </div>
      <div class="Page__header-sub empty" v-if="!parcours.is_linear && !$root.below('tablet')"/>
      <div class="Page__header-sub" v-if="$root.above('portrait')">
        <div class="HeaderParcours__bold">{{ trans('author.parcours') }}</div>
        <div class="HeaderParcours__author">{{ parcours.author.parcours }}</div>
      </div>
    </div>
    <div ref="close" class="HeaderParcours__close" :class="special" @click="onClick()">
      <div class="HeaderParcours__close-contenaire">
        <div class="HeaderParcours__txt" v-if="expanded">{{ trans('close') }}</div>
        <svg viewBox="0 0 14.7 14.71">
          <line class="cls-1" x1="0.35" y1="0.35" x2="14.34" y2="14.35"/>
          <line class="cls-1" x1="0.35" y1="14.35" x2="14.34" y2="0.35"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>

import Settings from '@/config/Settings'
import Dropdown from '@/components/app/Dropdown'
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'HeaderParcours',
  components: { Dropdown, ContentLoader },
  props: ['parcours', 'chapters', 'special'],
  translations: {
    en: {
      close: 'Close',
      author: {
        parcours: 'Parcours editor(s)',
      },
    },
    fr: {
      close: 'Retour',
      author: {
        parcours: 'Responsable(s) du parcours',
      },
    }
  },
  beforeDestroy() {
    TweenMax.to(this.$refs.close, 0.25, { alpha: 0, ease: Linear.easeNone })
  },
  data: () => ({
    current: {},
    expanded: false
  }),
  created: async function() {
    this.$scroll = document.querySelector('.Parcours')
    this.$is_locked = false
  },
  mounted() {
    TweenMax.from(this.$refs.close, 0.25, { alpha: 0, ease: Linear.easeNone, delay: 0.25 })
  },
  methods: {
    expand(b) {
      this.expanded = (b == true && window.innerWidth >= 1200) ? true : false
      const py = (b && this.special != 'special') ? 70 : 40
      TweenMax.to(this.$refs.close, 0.25, { css:{ right: py }, ease: Elastic.easeOut })
    },
    onClick() {
      this.$router.push({ params: { parcours: null, chapter: null }})
    },
    gotOnChapter(id) {
      this.$parent.scrollOnChapter(id)
      this.$is_locked = true
    },
    updateHeaderItem(id) {
      if(this.$refs.dropdown)
        this.$refs.dropdown.updateHeaderItem(id)
    },
    scrollToChapterCompleted() {
      this.$is_locked = false
      this.updateHeaderItem(Number(this.route.to.params.chapter)-1)
    },
  },
  computed: {
    route() { return this.$store.getters['app/route'] },
  },
  watch: {
    '$route': {
      immediate: true,
        handler() {
          if(this.parcours
          && this.route.to.params.chapter
          && this.route.from.params.chapter != this.route.to.params.chapter
          && this.parcours.is_linear
          && !this.$is_locked)
              this.updateHeaderItem(Number(this.route.to.params.chapter)-1)
        }
    },
  }
}
</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.HeaderParcours
  width 100%
  height 100%
  background-color white
  position relative
  display flex
  align-items center
  padding-bottom 20px

  &__author
    font-size 15px
    margin-top 10px

  +below(tablet)
    justify-content center
    align-items flex-start
    flex-direction column
    padding 0 0 0 32px

  +below(phone)
    padding 0 0 0 24px

  &__bold
    font-weight bold

  &__spin
    border 2px solid rgba(blue, .5)
    border-top 2px solid rgba(blue, .1)
    border-radius 50%
    width 20px
    height 20px
    animation HeaderParcours__spin 2s linear infinite
    margin 5px auto
    left -8px
    position relative
    @keyframes HeaderParcours__spin {
      from { transform: rotate(0deg) }
      to { transform: rotate(360deg) }
    }

  &__menu
    position absolute
    top -16px
    left -4px
    background-color #f6f4ea
    width 100%
    min-height 250px
    padding 0 4px
    display none
    box-shadow 0px 2px 2px 0px rgba(0,0,0,0.2)

  &__item
    padding 0px 16px 28px
    cursor pointer

    &.pos-0
      padding-top 16px

    &.is-header
      padding 0

  &__arrow
    position absolute
    cursor pointer
    width 16px
    height auto
    right 16px
    top 6px

  &__number
    margin-bottom 8px
    color blue

  &__close
    position fixed
    width 18px
    height 18px
    top 20px
    right 20px
    cursor pointer
    border 1px solid blue
    border-radius 50%
    display flex 
    align-items center
    justify-content center

    &-contenaire
      position absolute
      width 42%
      height 100%

  .relative
    position relative

  .empty
    border none

  .cls-1
    fill none
    stroke blue
    stroke-miterlimit 10
    stroke-width 2

  .cls-2
    fill none
    stroke blue
    stroke-miterlimit 10
    stroke-width 2

  &__txt 
    color blue
    position absolute
    font-size 12px
    right 18px
    top 3px

  .Page__header-sub
    +below(phone)
      width 100%


</style>











