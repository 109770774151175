import Vue from 'vue'
import Cookies from 'js-cookie'
import DefaultLanguage from '@/core/js/DefaultLanguage'
import Settings from '@/config/Settings'

const route = {
  name: null,
  meta: { 
    lang: DefaultLanguage, 
    name: null
  }
}

export default {

  namespaced: true,
  state: {
    expand_completed: 0,
    expand_middle: 0,
    lang: DefaultLanguage,
    mode: Settings.mode,
    parcours: 'explore',
    route: {
      from: route,
      to: route
    },
    reverse_completed: 0,
    reverse_middle: 0,
    show_gradient: false,
    navigation_locked: false,
    home_navigation_locked: false,
    toogle_search: false,
    fullscreen: null,
    use_dropdown: false
  },
  
  mutations: {
    'FULLSCREEN' (state, data) {
      state.fullscreen = data
    },
    'USE_DROPDOWN' (state, bool) {
      state.use_dropdown = bool
    },
    'DISABLE_TOOGLE' (state, bool) {
      state.disable_toogle = bool
    },
    'SHOW_GRADIENT' (state, bool) {
      state.show_gradient = bool
    }, 
    'HOME_NAVIGATION_LOCKED' (state, bool) {
      state.home_navigation_locked = bool
    }, 
    'TOOGLE_SEARCH' (state) {
      state.toogle_search = !state.toogle_search
    },
    'TOOGLE_NAVIGATION' (state) {
      state.navigation_locked = !state.navigation_locked
    },
    'RESET_NAVIGATION' (state) {
      state.navigation_locked = false
    },
    'EXPAND_MIDDLE' (state, data) {
      state.expand_middle += 1
    },
    'REVERSE_MIDDLE' (state, data) {
      state.reverse_middle += 1
    },
    'EXPAND_COMPLETED' (state, data) {
      state.expand_completed += 1
    },
    'REVERSE_COMPLETED' (state, data) {
      state.reverse_completed += 1
    },
    'SET_PARCOURS' (state, mode) {
      state.parcours = mode
    },
    'RESET_PARCOURS' (state) {
      state.parcours = ''
    },
    'SET_MODE' (state, mode) {
      state.mode = mode
    },
    'RESET_MODE' (state) {
      state.mode = Settings.mode
    },
    'SET_FROM_ROUTE' (state, from) {
      state.route.from = from
    },
    'SET_TO_ROUTE' (state, to) {
      state.route.to = to
    },
    'LANG_REFRESH' (state) {
      const l = state.lang
      state.lang = 'none'
      state.lang = l
    },
    'LANG_CHANGED' (state, lang) {
      state.lang = lang
      Cookies.set(Settings.cookies.lang, state.lang)
    },
  },

  getters: {
    expand_completed: state => state.expand_completed, 
    expand_middle: state => state.expand_middle,
    fullscreen: state => state.fullscreen,
    home_navigation_locked: state => state.home_navigation_locked,
    lang: state => state.lang,
    mode: state => state.mode,
    navigation_locked: state => state.navigation_locked,
    parcours: state => state.parcours,
    route: state => state.route,
    reverse_middle: state => state.reverse_middle,
    reverse_completed: state => state.reverse_completed,
    show_gradient: state => state.show_gradient,
    toogle_search: state => state.toogle_search,
    use_dropdown: state => state.use_dropdown, 
  }
}














