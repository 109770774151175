<template>
  <div class="ExploreParcours">
    <div class="Flex__start">
      <div>
        <div ref="thumbnail" class="ExploreParcours__thumbnail" :class="'is-' + id" @click="onClick()">
          <div class="ExploreParcours__cover" :style="{ backgroundImage: 'url('+ this.image +')' }" />
        </div>
      </div>
      <div class="ExploreParcours__block">
        <div class="ExploreParcours__title" v-html="parcours.title"/>
        <div class="ExploreParcours__description" v-html="parcours.description"/>
        <div class="Page__header-sub" v-html="parcours.author.parcours"/>
      </div>
    </div>
  </div>
</template>

<script>

import Settings from '@/config/Settings'

export default {

  name: 'ExploreParcours',
  props: ['id'],
  data: () => ({
    parcours: null
  }),
  created() {
    this.parcours = this.parcours[this.id]
  },
  mounted() {
    this.image = require('@/commons/img/camera_blue.png')
  },
  methods: {
    onClick() {
      if(this.$route.params.parcours === this.id)
        return
      this.$parent.expandImage(this.id)
      TweenMax.set(this.$refs.thumbnail, { alpha: 0 })
    },
  },
  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
  }
}
</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.ExploreParcours
  position relative
  padding-bottom 58px
  font-size 20px
  line-height 26px

  &__block
    padding 0 64px

  &__title
    font-size 21px
    line-height 27px
    font-family 'Libre Baskerville', serif
    font-weight bold
    padding-bottom 24px

  &__description
    font-size 16px
    line-height 27px
    padding-bottom 32px

    +below(tablet)
      padding-right 48px
      font-size 15px
      line-height 24px

  &__thumbnail
    width 532px
    height 332px
    cursor pointer
    position relative

  &__cover
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    transition transform 1s
    background-size cover
    background-position top center

</style>











