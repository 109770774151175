<template>
  <div class="Dropdown" :class="'type-'+ idx">
    <div ref="is_header" class="Dropdown__item is-header Flex__row-start" :class="'type-'+ idx" @click="toogleMenu()">
        <div class="Dropdown__number" v-if="idx==='parcours'">{{ current.order }}</div>
        <div class="Dropdown__title" v-html="current.title"/>
    </div>
    <div ref="menu" class="Dropdown__menu">
      <div class="Dropdown__item Flex__row-start" v-for="(n, index) in items" :class="'pos-'+ index + ' '+ idx" @click="onClick(n.id, index)">
        <div class="Dropdown__number" v-if="idx==='parcours'">{{ n.order }}</div>
        <div v-html="n.title"/>
      </div>
    </div>
    <svg ref="arrow" viewBox="0 0 12.73 7.51" class="Dropdown__arrow" @click="toogleMenu()" v-if="$items.length > 1">
      <polyline class="cls-2" points="0.37 0.34 6.37 6.77 12.37 0.34"/>
      }
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: [ 'items', 'idx', 'clickOnItem', 'scroll' ],
  data: () => ({
    current: {},
    is_open: false
  }),
  created() {
    this.$pos = 0
    this.current = this.items[this.$pos]
    this.$raf = null
    this.$old = null
    this.$items = []
    _.each(this.items, (chapter, index)=>{
      this.$items.push('.Dropdown__item.pos-'+ index + '.'+ this.idx)
    })
  },
  methods: {
    onLoop() {
      const offset = (!this.scroll) ? window.pageYOffset : this.scroll.scrollTop
      if(!this.$old)
        this.$old = offset
      else if(offset != this.$old){
        this.toogleMenu()
        return
      }
      this.$raf = requestAnimationFrame(this.onLoop)
    },
    onLoopCompleted() {
      cancelAnimationFrame(this.$raf)
      this.$old = null
    },
    toogleMenu() {

      if(this.$items.length <=1)
        return

      this.is_open = !this.is_open
      if(this.is_open) {

        TweenMax.set(this.$refs.menu, { css: { display: 'block' } })

        if(this.$tween_items) this.$tween_items.kill()
        this.$tween_items = new TimelineMax({})
            .set(this.$items,{ alpha: 0 })
            .staggerTo(this.$items, 0.15, { alpha: 1, ease: Linear.easeNone, delay: 0.1 }, .1)

        if(!this.$root.below('tablet'))
          this.onLoop()
      }
      else {
        TweenMax.set(this.$refs.menu, { css: { display: 'none' } })
        this.onLoopCompleted()
      }
    },

    onClick(id, pos) {

      this.toogleMenu()
      if(this.$pos === pos) return

      this.$pos = pos
      this.$parent[this.clickOnItem](id)

      const els = [ this.$refs.is_header, this.$refs.arrow ]
      this.$tween = TweenMax.to(els, 0.2,{ alpha: 0, ease: Linear.easeNone })

      if(this.idx === 'categories' || this.idx === 'search')
        this.updateHeaderItem(pos)
    },

    updateHeaderItem(pos) {

      this.current = (this.idx == 'categories') ? this.items[pos+1] : this.items[pos]
      if(this.$tween) this.$tween.kill()

      const els = [ this.$refs.is_header, this.$refs.arrow ]
      this.$tween = TweenMax.set(this.$refs.is_header, { alpha: 0 })
      this.$tween = TweenMax.to(els, 0.25, { alpha: 1, ease: Linear.easeNone, delay: 0.5 })
    },

    closeDropdown() {
      if(this.is_open) this.toogleMenu()
    }

  },

  watch: {
    is_open(){
      this.is_open
      ? document.addEventListener('click', this.closeDropdown)
      : document.removeEventListener('click', this.closeDropdown)

    }
  }
}
</script>

<style lang='stylus'>
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Dropdown
  font-size 13px
  line-height 19px

  &.type-search
    position relative
    font-size 16px

  &__menu
    position absolute
    top -16px
    left -4px
    background-color #f6f4ea
    width 100%
    padding 0 4px
    display none
    box-shadow 0px 2px 2px 0px rgba(0,0,0,0.2)

  &__item
    padding 0px 16px 28px
    cursor pointer

    +below(tablet)
      padding 0px 16px 12px

    &.pos-0
      padding-top 16px

    &.is-header
      padding 0

    &.type-search
      padding-left 16px

  &__arrow
    position absolute
    cursor pointer
    width 12px
    height auto
    right 16px
    top 6px

  &__title
    padding-right 30px
    line-height 16px

  &__number
    margin-right 12px
    color blue

  .cls-1
    fill none
    stroke #afafaf
    stroke-miterlimit 10

  .cls-2
    fill none
    stroke #afafaf
    stroke-miterlimit 10

</style>



