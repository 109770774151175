<template>
  <div class="MobileControl" :class="['is-'+ id, { 'active': active, 'locked': locked, 'verysm': verysm() } ]">
    <div class="txt" :class="{ 'verysm': verysm() }" v-html="content" @click="onClick(id)"/>
    <svg class="MobileControl__arrow" viewBox="0 0 22.19 57.96" :class="{ 'active': active }">
      <line class="cls-1" x1="11.09" x2="11.09" y2="57.2"/>
      <polyline class="cls-1" points="0.38 44.83 11.09 57.2 21.81 44.83"/>
    </svg>
  </div>
</template>

<script>

import Settings from '@/config/Settings'

export default {

  name: 'MobileControl',
  props: ['id', 'txt'],
  data: () => ({
    content: '',
    active: false,
    locked: false
  }),
  mounted() {
    this.setText()
    this.reset()
  },
  
  methods: {
    verysm() {
      return window.innerHeight < 700
    },
    setText() {
      if(this.txt.length > 3 && window.innerWidth < 768) {
        const split = _.split(this.txt, ' ')
        let txt = ''
        for(let c=0; c<3; c++) {
          txt += split[c] + ' '
        }
        txt += '...'
        if(split.length >= 3)
          this.content = txt
        else 
          this.content = this.txt
      }
      else 
        this.content = this.txt
    },
    reset() { 
      this.locked = this.getLocked()
      if(Number(this.$route.params.chapter) === this.id
      || !this.$route.params.chapter && this.id == 1 ) this.active = true
      else this.active = false
    },
    onClick(id) {
      this.$parent.updateControl(id)
    },
    getLocked() {
      if(!this.$route.params.chapter && this.id == 1) return false
      else if((Number(this.$route.params.chapter)) != this.id) return true
      else return false
    },
  },

  watch: {
    '$route': {
        immediate: true,
        handler() {
          this.reset()
      }
    },
    use_dropdown(nw, od) {
      if(nw) { 
        this.locked = false 
        this.active = false
      } else {
        this.reset()
      }
    },
  },

  computed: {
    use_dropdown() { return this.$store.getters['app/use_dropdown'] },
  },

}
</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.MobileControl
  position relative
  width 100%
  height 8vh
  transition 0.5s ease
  overflow hidden 
  background white

  &.is-1
    background #d8d6c3
  &.is-2
    background #dbdaca
  &.is-3
    background #e1e0d0
  &.is-4
    background #e6e6d7
  &.is-5
    background #ecebdf
  &.is-6
    background #f1efe5
  &.is-7
    background #f6f4ea
  &.is-8
    background #faf8ef

  &.active
    background white

  &.verysm
    height 7vh

  &.locked
    height 0vh

  &__arrow
    position absolute
    left 29px
    width 1.6vh
    opacity 0
    transition opacity 0.5s ease

    &.active
      opacity 1

  .txt
    position absolute
    cursor pointer
    left 62px
    top 3.6vh

    &.verysm
      top 2.2vh

    +below(tablet)
      font-size 13px
      left 50px

  .cls-1
    fill none
    stroke #afafaf
    stroke-miterlimit 10

</style>






























