<template>
  <div class="Front">
  </div>
</template>

<script>

export default {
  name: 'Front',
  methods: {
    show() {
      const promise = new Promise((resolve, reject) => { TweenLite.to(this.$el, 0.5, { alpha: 1, onComplete: resolve }) })
      return promise
    },
    hide() {
      const promise = new Promise((resolve, reject) => { TweenLite.to(this.$el, 0.5, { alpha: 0, onComplete: resolve }) })
      return promise
    },
  },
}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Front
  position fixed
  top 0px
  left 0px
  right 0px
  width 100vw
  height 100vh
  z-index 8000
  background-color beige
  opacity 1
  pointer-events none

</style>


