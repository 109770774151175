<template>
  <div class="Navigation" :class="[{ 'is-active': menuIsOpen }]">
    <div ref="background" class="Navigation__background" :class="[{ 'is-active': menuIsOpen }]"/>
    <div class="Navigation__container">
      <div class="Navigation__container__logo Flex__row-start" @click="onClick('logo')">
        <svg class="Navigation__logo" viewBox="0 0 102.34 33.55">
          <!-- <rect class="cls-1" :class="{ 'is-black': isBlack }" width="24.92" height="7.36"/> -->
          <!-- <rect class="cls-1" :class="{ 'is-black': isBlack }" x="8.4" width="8.13" height="24.92"/> -->

          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M4.42,10.08H0V6.23H13.08v3.85H8.66V33.17H4.42Z"/>
          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M15.12,6.23H26.66v3.85H19.35V17.2h5.81v3.85H19.35v8.27h7.31v3.85H15.12Z"/>
          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M28.82,26.93V12.47c0-4.16,2.15-6.62,6.23-6.62s6.23,2.46,6.23,6.62v2.84h-4V12.2c0-1.77-.84-2.5-2.11-2.5s-2.12.73-2.12,2.5v15c0,1.77.85,2.46,2.12,2.46s2.11-.69,2.11-2.46V23.12h4v3.81c0,4.16-2.15,6.62-6.23,6.62S28.82,31.09,28.82,26.93Z"/>
          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M43.82,6.23h4.24v11H52.6v-11h4.23V33.17H52.6V21.05H48.06V33.17H43.82Z"/>
          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M60,6.23h5.31l4.12,16.12h.08V6.23h3.77V33.17H68.91L63.83,13.5h-.08V33.17H60Z"/>
          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M76.41,6.23H88v3.85H80.64V17.2h5.81v3.85H80.64v8.27H88v3.85H76.41ZM77.18,0H81.8l2.69,4.42H81.3Z"/>
          <path class="cls-1" :class="{ 'is-black': isBlack }" d="M89.72,26.78V25.24h4v1.85c0,1.92.84,2.61,2.19,2.61S98.11,29,98.11,27c0-2.23-.84-3.66-3.61-6.08-3.54-3.12-4.7-5.31-4.7-8.39,0-4.23,2.19-6.69,6.31-6.69s6.16,2.46,6.16,6.77v1.12h-4V12.35c0-1.92-.77-2.65-2.12-2.65S94,10.43,94,12.27s.89,3.39,3.66,5.81c3.54,3.12,4.65,5.27,4.65,8.62,0,4.39-2.23,6.85-6.38,6.85S89.72,31.09,89.72,26.78Z"/>

        </svg>
        <div class="Navigation__title" :class="{ 'is-black': isBlack }" v-html="trans('logo')" />
      </div>
      <div class="Navigation__buttons" v-if="isMobile()" :class="{ 'is-open': menuIsOpen }">
        <div class="Flex__row-start" v-if="!menuIsOpen" @click="onClick('search')">
          <svg viewBox="0 0 13.5 13.5" class="Navigation__search">
            <circle class="cls-icon is-blue" cx="5.26" cy="5.26" r="4.76"/>
            <line class="cls-icon is-blue" x1="8.54" y1="8.54" x2="13.15" y2="13.15"/>
          </svg>
        </div>
        <div class="Navigation__lang" @click="$root.langSwitch()" v-if="!menuIsOpen">{{ lang == 'fr' ? 'EN' : 'FR' }}</div>
        <div class="Navigation__burger-wrapper" :class="[{ 'is-active': menuIsOpen }]" @click="menuIsOpen = !menuIsOpen">
          <span class="Navigation__burger" :class="{ 'is-open': menuIsOpen }"/>
        </div>
      </div>
      <div class="Navigation__buttons Flex__row-center-end" v-if="!isMobile()">
        <!-- 
        <div class="Flex__row-start">
          <svg viewBox="0 0 13.5 13.5" class="Navigation__search">
            <circle class="cls-icon" :class="{ 'is-black': isBlack }" cx="5.26" cy="5.26" r="4.76"/>
            <line class="cls-icon" :class="{ 'is-black': isBlack }" x1="8.54" y1="8.54" x2="13.15" y2="13.15"/>
          </svg>
        </div> 
        -->
        <router-link
          class="Navigation__link"
          :class="{ 'is-black': isBlack }"
          :to="{ name: 'home.' + lang, params: { categorie: (!this.$route.params.categorie) ? trans('Global.categorie-all') : this.$route.params.categorie }}">
          {{ trans('parcours') }}
        </router-link>
        <router-link
          class="Navigation__link"
          :class="{ 'is-black': isBlack }"
          :to="{ name: 'about.' + lang }">{{ trans('about') }}</router-link>
        <router-link
          class="Navigation__link"
          :class="{ 'is-black': isBlack }"
          :to="{ name: 'contact.' + lang }">{{ trans('contact') }}</router-link>
        <div class="Flex__row-start">
          <svg viewBox="0 0 13.5 13.5" class="Navigation__search">
            <circle class="cls-icon" :class="{ 'is-black': isBlack }" cx="5.26" cy="5.26" r="4.76"/>
            <line class="cls-icon" :class="{ 'is-black': isBlack }" x1="8.54" y1="8.54" x2="13.15" y2="13.15"/>
          </svg>
          <router-link
            class="Navigation__link"
            :class="{ 'is-black': isBlack }"
            @click.native="onClick('search')"
            to="">{{ trans('search') }}</router-link>
        </div>
        <div class="Flex__row-start">

          <transition name="Navigation__icon-database">
            <img class="Navigation__icon-database" src="@/components/asset/img/database_white.png" v-if="!isBlack" />
            <img class="Navigation__icon-database" src="@/components/asset/img/database_black.png" v-if="isBlack"/>
          </transition> 

          <a :href="trans('link')"
             target="_blank"
            class="Navigation__link"
            :class="{ 'is-black': isBlack }">{{ trans('connect') }}</a>
        </div>
        <div class="Navigation__lang" @click="$root.langSwitch()">{{ lang == 'fr' ? 'EN' : 'FR' }}</div>
      </div>

      <transition @enter="showItems" @leave="hideItems">
        <div class="Navigation__items" v-if="menuIsOpen">
          <div class="Navigation__item">
            <router-link
              class="Navigation__link"
              :class="{ 'is-black': isBlack }"
              :to="{ name: 'home.' + lang, params: { categorie: (!this.$route.params.categorie) ? trans('Global.categorie-all') : this.$route.params.categorie }}">{{ trans('parcours') }}</router-link>
          </div>
          <div class="Navigation__item">
            <router-link
              class="Navigation__link"
              :class="{ 'is-black': isBlack }"
              :to="{ name: 'about.' + lang }">{{ trans('about') }}</router-link>
          </div>
          <div class="Navigation__item">
            <router-link
              class="Navigation__link"
              :class="{ 'is-black': isBlack }"
              :to="{ name: 'contact.' + lang }">{{ trans('contact') }}</router-link>
          </div>
          <div class="Navigation__item">
            <div class="Flex__row-start">


              <transition name="Navigation__icon-database">
                <img class="Navigation__icon-database" src="@/components/asset/img/database_white.png" v-if="!isBlack" />
                <img class="Navigation__icon-database" src="@/components/asset/img/database_black.png" v-if="isBlack"/>
              </transition>



              <a :href="trans('link')"
                 target="_blank"
                class="Navigation__link"
                :class="{ 'is-black': isBlack }">{{ trans('connect') }}</a>
            </div>
          </div>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

export default {

  name: 'Navigation',
  data: () => ({
    isBlack: false,
    menuIsOpen: false,
    theLast: 0,
    direction: 'none'
  }),

  translations: {
    fr: {
      parcours: 'Les parcours',
      logo: 'ENCYCLOPÉDIE RAISONNÉE</br>DES TECHNIQUES DU CINÉMA',
      search: 'Recherche',
      contact: 'Nous joindre',
      about: 'À propos',
      connect: 'Base de données',
      link: 'http://www.encyclo-technes.org/fr/base',
    },
    en: {
      parcours: 'Parcours',
      logo: 'ENCYCLOPEDIA OF FILM</br> TECHNIQUES AND TECHNOLOGIES',
      search: 'Search',
      contact: 'Join us',
      about: 'About',
      connect: 'Database',
      link: 'http://www.encyclo-technes.org/en/base',
    },
  },

  beforeDestroy(){
    console.log('beforeDestroy !!!');
  },

  beforeCreate() {
    if(this.$root.below('tablet'))
      window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {

    console.log('update!');


    if(this.$root.below('tablet'))
      window.addEventListener('scroll', this.onScroll)
  },

  methods: {

    onScroll(e) {
      const st = (!this.$route.params.parcours)
      ? window.pageYOffset || document.documentElement.scrollTop
      : document.querySelector('.Parcours').scrollTop

      if (st > this.theLast) this.direction = 'up'
      else this.direction = 'down'
      this.theLast = st <= 0 ? 0 : st
    },

    isMobile() {
      if(this.$root.below('tablet')) return true
      else return false
    },

    onClick(arg) {
      switch(arg) {
        case 'logo':
          this.$router.push({ name: 'home.' + this.lang, params: { categorie: this.trans('Global.categorie-all'), parcours: null }})
          break
        case 'search':
          this.$store.commit('app/TOOGLE_SEARCH')
          break
      }
    },

    onNavigationLocked(b) {
      if(b) {
        this.setHeight(true)
        this.setBlack(true)
      } else {
        this.setHeight(false)
        if(this.show_gradient && this.$route.params.parcours || !this.home_navigation_locked) this.setBlack(false)
        else if(this.home_navigation_locked) this.setBlack(true)
      }
    },

    setHeight(b) {
      if(b || this.$root.below('tablet')) this.$tween = TweenMax.to(this.$refs.background, 0, {  height: '100%', ease: Cubic.easeOut })
      else this.$tween = TweenMax.to(this.$refs.background, 0, {  height: '0%', ease: Cubic.easeOut })
    },

    setBlack(b) {
      this.isBlack = (this.$root.below('tablet') && !this.menuIsOpen) ? true : b
    },

    showItems(el, done) {
      this.$tween = TweenMax.from(el, .5, { opacity: 0, delay: .25, onComplete: ()=>{ done() }})
    },

    hideItems(el, done) {
      if(this.$twen) this.$tween.kill()
      done()
    },

    updatePosition(direction) {
      const py = (direction == 'up' && direction != 'none') ? -200 : 0
      TweenMax.to(this.$el, .5, { y: py })
    },

  },

  watch: {

    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          
          if(this.route.from.params.parcours == this.route.to.params.parcours && this.$root.is_desktop)
            return

          this.direction = 'none'

          if(this.$route.params.parcours && this.$root.below('tablet'))
            TweenMax.delayedCall( 3, ()=>{ document.querySelector('.Parcours').addEventListener('scroll', this.onScroll) })
          else if(document.querySelector('.Parcours'))
            document.querySelector('.Parcours').removeEventListener('scroll', this.onScroll)

          if(this.menuIsOpen)
            TweenMax.delayedCall(.5, ()=>{ this.menuIsOpen = false })

          if(this.$route.meta.name == 'about'
          || this.$route.meta.name == 'contact') TweenMax.delayedCall( 1, ()=>{ this.onNavigationLocked(true) })
          else this.onNavigationLocked(false)
        })
      }
    },

    'direction'(nw, od){
      this.updatePosition(nw)
    },
    'menuIsOpen'(nw, od) {
      if(nw)
        TweenMax.set('#App', { css: { position: 'fixed' }})
      else
        TweenMax.set('#App', { css: { position: 'absolute' }})
      this.setBlack(false)
    },
    navigation_locked(nw, od) {
      if(nw) this.onNavigationLocked(true)
      else this.onNavigationLocked(false)
    },
    show_gradient(nw, od) {
      if(nw) this.setBlack(false)
    },
    home_navigation_locked(nw, od) {
      if(nw) this.setBlack(true)
      else this.setBlack(false)
    },

  },

  computed: {
    expand_completed() { return this.$store.getters['app/expand_completed'] },
    home_navigation_locked() { return this.$store.getters['app/home_navigation_locked'] },
    navigation_locked() { return this.$store.getters['app/navigation_locked'] },
    show_gradient() { return this.$store.getters['app/show_gradient'] },
    route() { return this.$store.getters['app/route'] },
  }

}
</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'
@import './styl/Hamburger-icon'


.Navigation
  position fixed
  top 0px
  left 0px
  right 0px
  width 100vw
  height 96px
  z-index 6000
  transition height .25s ease
  background linear-gradient(180deg, rgba(black,1) 0%, rgba(0,0,0,0) 100%)

  +below(tablet)
    height 60px

  &.is-active
    height 100vh


  &__background
    position absolute
    background-color white
    transition background 2s ease
    border-bottom 2px solid #f2f2f2
    width 100%
    height 0%
    top -2px
    left 0

    &.is-active
      background rgb(33,90,169)
      background linear-gradient(180deg, rgba(33,90,169,1) 0%, rgba(105,166,223,1) 100%)
      border-bottom 2px solid #69a6df

    +below(tablet)
      height 100%

  &__container
    padding 0 64px
    height 100%
    position relative
    display flex
    align-items center
    justify-content space-between

    +below(tablet)
      padding 18px 18px
      align-items flex-start

    &.is-locked
      background-color rgba(255,255,255,1)

  &__container__logo
    cursor pointer

  &__buttons
    &.is-open
      justify-content flex-end

    +below(tablet)
      width 50%
      max-width 130px
      display flex
      align-items center
      justify-content space-between

    +below(phone)
      max-width 100px

  &__lang
    border-radius 13px
    background-color blue
    color white
    font-size 11px
    width 26px
    height 26px
    text-align center
    line-height 26px
    cursor pointer
    display inline-block
    flex-basis 26px

    +below(tablet)
      margin-right 4px

  &__title
    font-size 12px
    line-height 14px
    color #fff
    padding-left 1vw
    padding-top 5px
    letter-spacing 0.5px
    margin-top -2px
    transition color 1s ease

    +below(tablet)
      font-size 11px

    +below(portrait)
      font-size 8px
      line-height 10px
      padding-left 2vw

    &.is-black
      color black

  &__link
    transition color 1s ease
    color white
    padding-right 32px

    +below(screen)
      padding-right 24px
      font-size 13px

    +below(tablet)
      font-size 18px
      &.router-link-exact-active
      &.router-link-active
        text-decoration underline

    &:link
    &:hover
    &:visited
    &:active
      color white

    &.is-black
      color black

      &:link
      &:hover
      &:visited
      &:active
        color black

  &__logo
    width 87px

    +below(portrait)
      width 66px

  &__translate
    width 23px

  &__search
    width 16px
    padding-right 8px
    cursor pointer

    +below(tablet)
      width 20px
      padding-right 0px

  &__icon-database
    width 16px
    margin-right 10px
    margin-top -2px
    transition opacity 1s
    transition-delay 1s

    &-enter
    &-leave-active
      opacity 0

  &__connect
    width 12px
    padding-right 10px
    cursor pointer

  .cls-1
    transition fill 1s ease
    fill #fff

    &.is-black
      fill black

    &.is-blue
      fill blue

  .cls-2
    fill #1f95ff

  .cls-icon
    fill none
    stroke #fff
    stroke-miterlimit 10
    transition stroke 1s ease

    &.is-black
      stroke black

    &.is-blue
      stroke blue

  &__burger
    hamburger-generator(25px, 2px, 5px, blue, $timing-function: linear)
    &.is-open
      background white
      &:before
      &:after
        background white

  &__burger-wrapper
    cursor pointer
    &.is-active
      .Navigation__burger
        hamburger-animation()
        color white
        fill white
        stroke white

  &__items
    position absolute
    width 100vw
    height 75vh
    top 12.5%
    display flex
    align-items flex-start
    justify-content center
    flex-direction column
    padding-left 25vw

  &__item
    cursor pointer
    padding 16px 0
    width 50vw


</style>


