<template> 
  <div class="InputComp" :style="{ zIndex: zindex }">  
    <input ref="name" :placeHolder="placeholder" class="InputComp__input" type="text" name="input" v-model="name">
  </div>
</template>

<script>

export default {

  name: 'InputComp',
  props: ['obj', 'zindex'],

  data: () => ({
    name: '',
    placeholder: ''
  }),

  created() {

    switch(this.obj) {
      case 'title':
        this.placeholder = 'Institution de conservation'
        break
      case 'topic':
        this.placeholder = 'Inscrire un sujet'
        break
      case 'date':
        this.placeholder = 'Inscrire une date'
        break
    }
  },

  mounted() {
  },

  methods: {
  },

  watch: {
  },

  computed: {
  }

}

</script>

<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.InputComp
  width 20%
  position relative
  border-left 1px solid grey
  z-index 900
  padding-top 6px
  padding-left 12px

  &__input
    font-family 'IBM Plex Sans', sans-serif 
    font-size 16px
    border none
    color black
    width 100%
    background none

  &__input::placeholder
    color grey
    font-size 12px


</style>



