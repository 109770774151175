<template>
  <div class="Single">
    <template v-if="chapter">
      <Chapter :index="null" :chapter="chapter" />
      <!--div class="Chapter">
        <router-link :to="{ name: 'home.' + lang, params: { parcours: $route.params.parcours } }" class="Single__back">
          {{ trans('back') }}
        </router-link>
      </div-->
    </template>
  </div>
</template>

<script>
import Chapter from '@/components/chapter/Chapter'

export default {
  name: 'Single',
  components: { Chapter },
  data: () => ({
    chapter: null
  }),
  translations: {
    en: { back: 'Retour au parcours' },
    fr: { back: 'Retour au parcours' }
  },
  created: async function() {
    const chapter = await this.getChapters([this.$route.params.id])
    if(chapter)
      this.chapter = chapter.hidden[0] || chapter.visible[0]
  }
}
</script>


<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Single
  background beige
  position relative
  z-index 9999
  min-height 100vh
  padding 10vh 0

</style>

