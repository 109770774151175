<template>
  <div class="ImageExpanded" :class="{ 'is-fixed': isFixed }">
    <div ref="container" class="ImageExpanded__container">
      <div class="ImageExpanded__cover" :style="{ backgroundImage: 'url('+ parcour.cover +')' }" />
    </div>
    <div ref="gradient" class="ImageExpanded__gradient" />
  </div>
</template>

<script>

import Settings from '@/config/Settings'

export default {
  name: 'ImageExpanded',
  props: ['id'],
  data: () => ({
    isFixed: false,
  }),
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.onHideGradient()
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    switch(this.parcours) {
      case 'thumbnail' :
        this.showRegular()
        break
      default :
          this.showWithoutMotion()
    }
  },
  methods: {
    toggle() {
      TweenMax.to('Blocks', 1, { className: '-=is-active'})
      TweenMax.to(block, 1, { className: '+=is-active'})
    },
    showModePromotion() {
      const promise = new Promise((resolve, reject) => {
        TweenMax.set(this.$refs.container, { x: 0, y: 0, width: window.innerWidth, height: window.innerHeight, opacity: 0 })
        TweenLite.to(this.$refs.container, 0.5, { alpha: 1, onComplete: ()=>{
          resolve()
          this.showGradient()
        }, delay: 0.2 })
      })
      return promise
    },
    showWithoutMotion() {
      TweenMax.set(this.$refs.container, { x: 0, y: 0, opacity: 1, width: window.innerWidth, height: window.innerHeight})
      this.showGradient()
    },
    onResize() {
      TweenMax.set(this.$refs.container, { width: window.innerWidth })
    },
    expandImage(id) {
      this.id = id
      TweenMax.set(this.$el, { css: { zIndex: 5500 } })
      const nw = document.querySelector('.ExploreParcours__thumbnail.is-' + id).getBoundingClientRect()
      const px = (window.innerWidth/2) - (nw.width/2)
      const py = (window.innerHeight/2) - (nw.height/2)
      const timeline = new TimelineMax()
            .set(this.$refs.container, { x: nw.x, y: nw.y, opacity: 1, width: nw.width, height: nw.height })
            .to(this.$refs.container, 0.25, {
              x: px,
              y: py,
              ease: Linear.easeNone,
              delay: 0.2,
              force3D:true,
            })
            .to(this.$refs.container, 0.5, {
              x: 0,
              y: 0,
              width: window.innerWidth,
              height: window.innerHeight,
              onComplete: ()=>{
                this.$store.commit('app/RESET_NAVIGATION')
                this.$store.commit('app/SET_PARCOURS', 'explore')
                this.$router.push({ params: { parcours: this.id, chapter: null }})
              },
              ease: Expo.easeOut,
            })
    },

    showRegular() {

      this.$parent.$parent.disableScroll()

      const nw = this.$parent.$thumb.getBoundingClientRect()
      const px = (window.innerWidth/2) - (nw.width/2)
      const py = (window.innerHeight/2) - (nw.height/2)
      const timeline = new TimelineMax()
            .set(this.$refs.container, { x: nw.x, y: nw.y, opacity: 1, width: nw.width, height: nw.height })
            .to(this.$refs.container, 0.25, {
              x: px,
              y: py,
              onComplete: ()=>{
                this.expandMiddle()
                this.$parent.$parent.enableScroll()
              },
              ease: Linear.easeNone,
              delay: 0.2,
              force3D:true,

            })
            .to(this.$refs.container, 0.5, {
              x: 0,
              y: 0,
              width: window.innerWidth,
              height: window.innerHeight,
              onComplete: ()=>{ this.expandCompleted() },
              ease: Expo.easeOut,
            })
    },
    hideModePromotion(delay) {
      TweenLite.to(this.$refs.container, 0.5, { alpha: 0, delay: delay })
    },
    hideRegular() {

      this.$parent.$parent.disableScroll()

      const nw = this.$parent.$thumb.getBoundingClientRect()
      if(window.scrollY <250) {
        TweenMax.to(this.$refs.container, 0.50, {
          alpha: 0,
          ease: Linear.easeNone,
          delay: 0.5,
          onComplete: ()=>{
            this.reverseCompleted()
            this.$parent.$parent.enableScroll()
          },

        })
        return
      }
      TweenMax.to(this.$refs.container, 0.50, {
        x: nw.x,
        y: nw.y,
        width: nw.width,
        height: nw.height,
        ease: Cubic.easeOut,
        onComplete: ()=>{
          this.reverseCompleted()
          this.$parent.$parent.enableScroll()
        },
        delay: 0.5,
      })
    },
    reverseCompleted() {
      this.$store.commit('app/REVERSE_COMPLETED')
    },
    expandMiddle() {
      this.$store.commit('app/EXPAND_MIDDLE')
    },
    expandCompleted() {
      this.$store.commit('app/EXPAND_COMPLETED')
      this.showGradient()
    },
    showGradient() {
      this.$store.commit('app/SHOW_GRADIENT', true)
    },
    onShowGradient() {
      TweenMax.to(this.$refs.gradient, 0.5, { alpha: 1, ease: Linear.easeNone })
    },
    hideGradient() {
      this.$store.commit('app/SHOW_GRADIENT', false)
    },
    onHideGradient(val) {
      const speed = (val) ? val : .2
      if(this.$refs.gradient)
        TweenMax.to(this.$refs.gradient, val,{ alpha: 0, ease: Linear.easeNone })
    },

  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if(!this.route.to.params.chapter)
          this.hideGradient()
      }
    },
    show_gradient(nw, od) {
      if(nw)
        this.onShowGradient()
      else if(!this.$route.params.parcours)
        this.onHideGradient()
    },
    navigation_locked(nw, od) {
      if(!nw && !this.$route.params.parcours)
         this.onHideGradient(0)
    }
  },
  computed: {
    parcours_data() { return this.$store.getters['data/parcours'] },
    parcour() { return _.find(this.parcours_data, p => p.id == this.id) },
    parcours() { return this.$store.getters['app/parcours'] },
    navigation_locked() { return this.$store.getters['app/navigation_locked']},
    route() { return this.$store.getters['app/route'] },
    show_gradient() { return this.$store.getters['app/show_gradient'] },
    transition() { return this.$store.getters['app/transition'] },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ImageExpanded

  position fixed
  pointer-events none
  width 100vw
  &.is-fixed
    position fixed

  &__block
    height 0
    overflow hidden
    &.is-active
      height auto

  &__gradient
    background rgb(0,0,0)
    background linear-gradient(180deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0) 100%)
    top 0
    left 0
    position fixed
    width 100%
    height 100%
    opacity 0

  &__container
    width 532px
    height 332px
    opacity 0
    background-size cover
    background-position top center
    resize both 

  &__cover
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    transition transform 1s
    background-size cover
    background-position top center
    resize both 

</style>