<template>
  <div class="Thumbnails Flex__center">
    <transition @enter="enter" @leave="leave" mode="out-in">
      <div ref="container" class="Thumbnails__container" :key="$route.params.categorie">
        <Thumbnail :key="n" v-for="n in thumbnails" :id="n" />
      </div>
    </transition>
  </div>
</template>

<script>

import Thumbnail from '@/components/home/Thumbnail'

import smoothscroll from 'smoothscroll-polyfill';
       smoothscroll.polyfill()



export default {
  name: 'Thumbnails',
  components: { Thumbnail },
  methods: {
    enter(el, done) {
      if(!this.route.to.params.categorie)
        done()
      else {
        if(navigator.maxTouchPoints == 5) {
          const el = document.querySelector('.Home__container')
                el.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })
        } 
        else {
          TweenMax.from(this.$refs.container, 0.75, { 
            y: 2000, 
            alpha: 0, 
            ease: Expo.easeOut, 
            delay: 0.2, 
            onComplete: ()=>{ done() }
          })
          TweenMax.to(document.documentElement, .5, { 
            scrollTo: window.innerHeight, 
            ease: Power2.easeOut 
          })
        }
      }
    },
    leave(el, done) {
      done()
    },
  },
  computed: {
    parcours_data() { return this.$store.getters['data/parcours'] },
    route() { return this.$store.getters['app/route'] },
    thumbnails() {
      const thumbnails = _.map(this.parcours_data, p => p.id)
      const category = this.$route.params.categorie
      let res = []
      _.each(thumbnails, (id)=>{
        if(!category || category === this.trans('Global.categorie-all') || this.parcours_data[id].categories.indexOf(category) != -1)
          res.push(id)
      })
      return res
    }
  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Thumbnails
  display flex
  width 100%
  color red

  &__container
    width 100%
    max-width 1088px
    // min-height 100vh
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content space-between

  &__empty
    width 532px
    height 332px
    margin-bottom 64px

</style>

