export default {
  // head() {
  //   return {
  //     title: this.meta.title,
  //     meta: [ { vmid: 'description', name: 'description', content: this.meta.description } ]
  //   }
  // },
  computed: {
    meta() {
      return _.extendWith(
        _.clone(this.general.meta[this.lang]),
        _.clone(this.page.meta[this.lang]),
        (g, p) => p != '' ? p : g
      )
    },
    general() { return this.$store.getters['data/general'] },
    pages() { return this.$store.getters['data/pages'] },
    page() { return this.pages[this.$route.meta.name] }
  }
}
