<template>
  <div class="Page Contact">
    <div class="Contact__container">
      <div class="Contact__left">
        <div class="Contact__thank" v-if="emailSended">
          {{ trans('thanks') }}
        </div>
        <div class="Contact__form" v-if="!emailSended">
          <div class="Chapter__title">{{ trans('title') }}</div>
          <input type="text" :class="{ 'is-error': error.name }" v-model="name" :placeholder="trans('name')" />
          <input type="text" :class="{ 'is-error': error.email }" v-model="email" :placeholder="trans('email')" />
          <input type="text" v-model="subject" :placeholder="trans('subject')" />
          <label>{{ trans('message') }}</label>
          <textarea v-model="text" />
          <input @click="send" type="submit" :value="trans('send')">
        </div>
      </div>
      <div class="Contact__right">
        <div>
          <strong>{{ trans('coord') }}</strong>
        </div>
        <a target="_blank" href="mailto:info@technes.org">info@technes.org</a>
        <div class="Contact__follow" v-html="trans('follow')" />
      </div>
    </div>
  </div>
</template>

<script>
import Page from '@/core/mixins/Page'

export default {
  name: 'Contact',
  head() {
    return {
      title: this.trans('encyclopedia'),
      titleTemplate: '%s - '+ this.trans('subtitle'),
    }
  },
  mixins: [ Page ],
  computed: {
    emailValid() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase())
    }
  },
  data: () => ({
    name: '',
    email: '',
    subject: null,
    text: null,
    emailSended: false,
    error: { name: false, email: false },
  }),
  translations: {
    fr: {
      encyclopedia: 'Encyclopédie raisonnée des techniques du cinéma TECHNÈS', 
      subtitle: 'Nous joindre',
      title: 'Nous écrire',
      name: 'Nom (requis)',
      email: 'Adresse courriel (requis)',
      subject: 'Sujet',
      message: 'Message',
      send: 'Envoyer',
      thanks: 'Merci',
      coord: 'Nos coordonnées',
      follow: `
        <strong>Pour suivre l'actualité du partenariat TECHNÈS</strong>,
        n'hésitez pas à consulter le <a href="http://technes.org/" target="_blank">site du projet</a>,
        à vous abonner à notre <a target="_blank" href="http://technes.org/infolettres-et-communiques">infolettre</a> et à nous suivre sur
        <a target="_blank" href="https://www.facebook.com/TECHNES.cinema/">Facebook</a> et
        <a target="_blank" href="https://twitter.com/technes_org">Twitter</a>.
      `
    },
    en: {
      encyclopedia: 'Encyclopedia of Film Techniques and Technologies, TECHNÈS', 
      subtitle: 'Contact us',
      title: 'Write us',
      name: 'Your name (required)',
      email: 'Your email address (required)',
      subject: 'Suject',
      message: 'Your message',
      send: 'Send',
      thanks: 'Thank you',
      coord: 'Our contact information',
      follow: `
        <strong>To get the latest TECHNÈS partnership news</strong>,
        visit our <a href="http://technes.org/" target="_blank">website</a>, subscribe
        to our <a target="_blank" href="http://technes.org/infolettres-et-communiques">newsletter</a>, and follow us on
        <a target="_blank" href="https://www.facebook.com/TECHNES.cinema/">Facebook</a> and
        <a target="_blank" href="https://twitter.com/technes_org">Twitter</a>.
      `
    },
  },
  methods: {
    validate() {
      this.error.name = this.name == '' ? true : false
      this.error.email = !this.emailValid
      return !this.error.name && !this.error.email
    },
    send: async function() {
      if(!this.validate()) return
      const form = new FormData
      form.append('name', this.name)
      form.append('subject', this.subject)
      form.append('email', this.email)
      form.append('text', this.text)
      const res = await this.$api.origin.post('mail', form)
      this.emailSended = true
    },
    enter(done) {
      const promise = this.$parent.$refs.front.hide()
      promise.then(() => {
        done()
      })
    },
    leave(done) {
      const promise = this.$parent.$refs.front.show()
      promise.then(() => {
        done()
      })
    },
  }
}

</script>

<style lang="stylus">
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Contact
  padding-top 200px
  width 100vw

  +below(tablet)
    padding-top 90px

  +below(portrait)
    // padding-top 96px
    padding-top 0

  &__thank
    font-size 26px
    text-align center
    padding 50px 0

  a
    color blue

  &__container
    max-width 1000px
    display flex
    margin 0 auto

    +below(portrait)
      align-items flex-start
      flex-direction column

  &__follow
    margin-top 40px

  &__form
    position relative
    box-sizing content-box
    margin 30px
    font-size 20px
    input
    label
    textarea
      display block
      font-size 20px
      width 100%
      border none
      border-bottom 1px solid grey
      padding 10px 0
      color grey-dark
      margin-bottom 30px
      transition border .5s
      &.is-error
        border-bottom 1px solid red
      &::placeholder
        color grey

      +below(tablet)
        font-size 16px

    textarea
      border 1px solid grey
      min-height 150px

    input[type=submit]
      color white
      background-color #1f95ff
      border 1x solid #1f95ff

    +below(portrait)
      margin-top 54px

  label
    color grey-dark
    border none
    margin-bottom 0

  &__left
    width 70%
    background white

    +below(tablet)
      margin-left 16px

    +below(portrait)
      padding-top 90px
      width 100%
      margin 0

  &__right
    width 30%
    padding-left 100px
    font-size 13px
    line-height 18px

    +below(tablet)
      padding 0 24px 0 64px

    +below(portrait)
      width calc(100% - 64px)
      margin 32px
      padding 0

</style>




















