import _ from 'lodash'
import Settings from '@/config/Settings'
import DefaultLanguage from '@/core/js/DefaultLanguage'

export const mappingRoutes = (route_map) => {
  const map = []

  _.each(Settings.locale, lang => {
    let prefix = (Settings.prefixRoute) ? '/' + lang : ''
    _.each(route_map, route => {
      if(route[lang].charAt(0) != '/')
        route[lang] = '/' + route[lang]

      map.push({
        path: prefix + route[lang],
        name: route.name + '.' + lang,
        meta: {
          name: route.name,
          lang: lang,
          id: route.id
        },
        component: require('@/pages/' + route.component).default
      })
    })
  })

  // les routes doivent etre unique
  if(map.length !== _.uniqBy(map, 'path').length)
    throw new Error('DUPLICATE ROUTE FOUND, CHECK Route.js')

  // redirection sur la home (lang detect) + 404
  map.push({ path: '/', redirect: { name: 'home.' + DefaultLanguage }})
  map.push({ path: '/en', redirect: { name: 'home.en'}})
  map.push({ path: '/fr', redirect: { name: 'home.fr'}})
  // map.push({ path: '*', redirect: { name: '404.' + DefaultLanguage }})
  map.push({ path: '*', redirect: { name: 'home.' + DefaultLanguage }})

  return map
}
