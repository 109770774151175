export default {
  fr: {
    Global: {
        'categorie-all': 'tous',
    }
  },
  en: {
    Global: {
        'categorie-all': 'all'
    }
  }
}
