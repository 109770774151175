export default {
  author: 'Origin',
  name: 'Origin',
  cookies: {
    lang: 'origin_lang',
  },
  locale: ['fr', 'en'],
  loader: true,
  // out-in -------- in-out -------
  mode: 'out-in',
  tempo: .5,
  prefixRoute: true,
  cms: true
}
