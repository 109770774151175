<template>
  <div class="Carroussel" :class="{ 'is-pdf': $route.meta.name == 'pdf' }">

    <div class="swiper-container" ref="swiper" v-if="$route.meta.name != 'pdf'">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="asset in assets">

          <!-- <div class="Carroussel__element"> -->
            <div class="Carroussel__img" :class="{ 'is-desktop': $root.is_desktop }" v-html="asset.html" @click="onClick(asset.html)"/>
            <div class="Carroussel__infos">
              <!--div class="Carroussel__creator" v-if="asset.creator" v-html="asset.creator" /-->
              <div class="Carroussel__description">
                <div class="Carroussel__txt" v-html="asset.description"  v-if="asset.description"/>
                <a target="_blank" :href="asset.url_public" :class="{ 'Carroussel__is-database' : isDatabase(asset.url_public) }">
                  {{ trans('btn') }}
                  <!-- <img src="@/components/asset/img/database.png" /> -->
                </a>
              </div>
            </div>

          <!-- </div> -->
        </div>
      </div>
      <template v-if="assets.length > 1">
        <div ref="next" class="swiper-button-next"></div>
        <div ref="prev" class="swiper-button-prev"></div>
      </template>
    </div>

    <div v-else  class="Carroussel__pdf">
      <div class="Carroussel__pdf-img" v-for="asset in assets">
        <div class="Carroussel__img" v-html="asset.html" />
        <div class="Carroussel__infos">
          <div class="Carroussel__description">
            <div v-html="asset.description"  v-if="asset.description"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Swiper from 'swiper'
require('swiper/css/swiper.css')

export default {
  name: 'Carroussel',
  props: ['assets'],
  translations: {
    en: { btn: 'See database entry' },
    fr: { btn: 'Voir la fiche' },
  },
  mounted() {
    this.$swiper = new Swiper(this.$refs.swiper, {
      navigation: {
        nextEl: this.$refs.next,
        prevEl: this.$refs.prev,
      }
    })
  },
  methods: {
    onClick(html) {
      if(!this.$root.below('phone'))
        this.$store.commit('app/FULLSCREEN', html)
    },
    isDatabase(link) {
      if(link.search('encyclo-technes.org/fr/base') > -1 
      || link.search('encyclo-technes.org/en/base') > -1) 
        return true
      else 
        return false
    },
  }
}
</script>

<style lang='stylus'>
@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.Carroussel
  position relative
  margin 64px 0
  max-height 532px

  +below(tablet)
    margin 48px 0 32px

  &.is-pdf
    height auto
    max-height none

  &__pdf
    &-img
      margin-bottom 20px

  &__description
    a
      margin-top 0px
      display block

    img
      margin-left 6px
      width 12px
      fill none
      stroke-miterlimit 10
      stroke blue
      position relative
      top 3px

  &__txt
    padding 16px 0 8px

    & p 
      margin 0

  &__infos
    line-height 14px
    font-size 12px
    margin-top 8px

  &__img
    max-height 450px
    cursor pointer
    *
      max-height 450px

      +below(tablet)
        height 40vw
    img
      height 100%
      +below(tablet)
        height 40vw

      +below(tablet)
        object-fit cover  

  &__img 
    &.is-desktop
      & img
        min-height 400px     

  .swiper-container
    width 100%
    height 100%

  .swiper-slide
    text-align center
    font-size 18px
    display flex
    justify-content center
    align-items center
    flex-direction column

  .swiper-button-prev
  .swiper-button-next
    color #7f7f7f
    transform translateY(-50%)

    +below(tablet)
      transform translateY(0%)
      top 20vw
   
  .swiper-button-prev:focus
  .swiper-button-next:focus
    border none
    outline none

  .swiper-button-prev:after
  .swiper-button-next:after
    font-family swiper-icons
    font-size 32px
    font-weight 200

    +below(tablet)
      font-size 16px
        

  &__is-database
    display inline-block
    padding-right 20px
    position relative
    &:after
      background-image url('../asset/img/database.png')
      background-size contain
      background-repeat no-repeat
      position absolute
      top 1px
      right 0px
      width 12px
      height 20px
      content ''

 
</style>



















