<template>
  <div id="PDF" class="PDF">

    <div class="PDF__title" v-if="parcour">{{ parcour.title }}</div>

    <template v-if="!ready">
      Création du pdf, veuillez patienter. Une nouvelle page va s'ouvrir.
      <div class="Chapter__loading" >
        <div>
          <div>
            ({{ loaded }}/{{ totalChapters }})
          </div>
          <div class="Chapter__spin" />
        </div>
      </div>
    </template>


    <template v-if="chapters">


      <div v-show="ready">

        <Chapter
          v-for="(chapter, index) in chapters.introduction"
          :key="'intro_' + index"
          :chapter="chapter"
          :index="null"
          :is_linear="true"
          :isIntro="true"
          @loaded="onLoaded"
        />

        <Chapter
          v-for="(chapter, index) in chapters.visible"
          :index="index"
          :key="index"
          :chapter="chapter"
          :is_linear="true"
          @loaded="onLoaded"
        />

      </div>
    </template>

  </div>
</template>

<script>
import Chapter from '@/components/chapter/Chapter'

export default {
  name: 'Single',
  components: { Chapter },
  computed: {
    parcours() { return this.$store.getters['data/parcours'] },
    ready() { return this.chapters && this.loaded == this.totalChapters },
    totalChapters() {
      if(!this.chapters) return 0
      return this.chapters.visible.length + this.chapters.introduction.length
    }
  },
  data: () => ({
    chapters: null,
    parcour: null,
    loaded: 0,
  }),
  watch: {
    ready() {
      if(this.ready) {
        this.$nextTick(() => {
          setTimeout(() => {
            window.print()
          }, 5000)
        })
      }
    }
  },
  created: async function() {
    this.parcour = this.parcours[this.$route.params.slug]
    this.chapters = await this.getChapters(this.parcour.chapters)
  },
  methods: {
    onLoaded() { this.loaded++ }
  }
}
</script>


<style lang='stylus'>

@import '~@/commons/stylus/Media-queries'
@import '~@/config/Settings'

.PDF
  background beige
  position relative
  z-index 9999
  min-height 100vh
  padding 5vw

  &__title
    font-size 40px
    font-family 'Libre Baskerville'
    margin-bottom 5vw

  .Carroussel__img
    height inherit
    max-width 600px
    img
      height inherit

</style>

