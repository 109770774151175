import _ from 'lodash'
import Vue from 'vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'

import App from '@/App.vue'
import store from '@/vuex/Store'
import { mappingRoutes } from '@/Route.js'
import TranslationMixin from '@/core/mixins/Translation'
import CommonsMixin from '@/mixins/Commons'
import LangMixin from '@/core/mixins/Lang'
import '@/core/js/Copyright'
import Settings from '@/config/Settings'
import isMobile from 'ismobilejs'

import { TweenMax, CSSPlugin, ScrollToPlugin } from 'gsap/all'
// because of three shaking
const gsapPlugins = [ TweenMax, CSSPlugin, ScrollToPlugin ]

Vue.prototype.$api = {
  acf: Axios.create({ baseURL: '/wp-json/acf/v3' }),
  origin: Axios.create({ baseURL: '/wp-json/origin/v1' }),
}

Vue.use(VueRouter)
Vue.use(Meta,{
  keyName: 'head',
  refreshOnceOnNavigation: true
})
Vue.use(VueLazyload, {
  lazyComponent: true,
  preLoad: 3,
})
Vue.mixin(TranslationMixin)
Vue.mixin(CommonsMixin)

const init = async () => {

  if(process.env.NODE_ENV !== 'production' && Settings.cms) {
    const res = await Vue.prototype.$api.acf.get('pages', { params: { hide_empty: 0, per_page: 500 }})
    window.__ENV = {}
    window.__ENV['ROUTES'] = _.map(res.data, d => ({
      id: d.id,
      name: d.acf.admin.route_key,
      component: d.acf.admin.component,
      fr: d.acf.admin.fr.slug,
      en: d.acf.admin.en.slug
    }))
  }

  if(!Settings.cms) {
    // hardcoded routes
    window.__ENV = {}
    window.__ENV['ROUTES'] = [

      { id: 'home', name: 'home', component: 'home/Home', fr: 'parcours/:categorie?/:parcours?/:chapter?', en: 'parcours/:categorie?/:parcours?/:chapter?' },
      { id: 'about', name: 'about', component: 'about/About', fr: 'a-propos', en: 'about' },
      { id: 'contact', name: 'contact', component: 'contact/Contact', fr: 'contact', en: 'contact' },

    ]
  }

  // console.log(mappingRoutes(window.__ENV['ROUTES']))

  const router = new VueRouter({
    mode: 'history',
    routes: mappingRoutes(window.__ENV['ROUTES']),
  })

  new Vue({
    el: '#App',
    store,
    router,
    mixins: [ LangMixin ],
    translations: {
    en: { title: 'Encyclopedia of Film Techniques and Technologies, TECHNÈS', subtitle: 'Parcours' },
    fr: { title: 'Encyclopédie raisonnée des techniques du cinéma TECHNÈS', subtitle: 'Parcours' },
  },
  head() {
    return {
      title: this.trans('title'),
      titleTemplate: '%s - '+ this.trans('subtitle'),
    }
  },
    data: () => ({
      width: window.innerWidth,
      technes_url: 'http://www.encyclo-technes.org',
      is_desktop: false,
      orientation: 'portrait'
    }),
    beforeCreate() {
      this.$store.commit('app/LANG_CHANGED', this.$route.meta.lang)
      this.$store.commit('app/SET_FROM_ROUTE', this.$route)
      this.$store.commit('app/SET_TO_ROUTE', this.$route)
    },
    created() {
      window.addEventListener('resize', this.onResize)
      window.addEventListener("orientationchange", this.onOrientationChange)
      this.$sizes =  {
        phone: 767,
        portrait: 768,
        tablet: 1024,
        screen: 1280,
      }
    },
    mounted() {
      this.is_firefox = navigator.userAgent.indexOf("Firefox") !== -1 ? true : false
      this.is_android = navigator.userAgent.indexOf("Android") !== -1 ? true : false
      this.is_desktop = !isMobile(window.navigator).any || navigator.maxTouchPoints != 5
      this.isPhone = isMobile(window.navigator).phone
      this.isTablet = isMobile(window.navigator).tablet || navigator.maxTouchPoints == 5

      

      this.setOrientation()
    },
    methods: {

      setOrientation() {
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.orientation = this.width > this.height ? 'landscape' : 'portrait'
      },

      onResize() { 
        this.setOrientation()
        if(!isMobile(window.navigator).phone && !isMobile(window.navigator).tablet)
          this.onReload()
      },
      onOrientationChange() {
        this.setOrientation()
        this.onReload()
      },
      onReload() {  
        TweenMax.set('#App', { opacity: 0 })
        if(this.$tween)
          this.$tween.kill()
        this.$tween = TweenMax.delayedCall(.5, ()=>{
          const reload = this.$router.resolve({ query: { forceReload: undefined } })
          window.location.href = reload.href
        })
      },
      below(type) { return this.width <= this.$sizes[type] },
      above(type) { return this.width >= this.$sizes[type] }
    },
    render: h => h(App)
  })
}

init()
