
import cleaner from 'clean-html'

export default {
  methods: {
    api: async function(segment, forceLang) {
      const res = await this.$api.origin.get('get', { params: { url: segment, lang: forceLang ? forceLang : this.lang } })
      return res.data.data
    },
    // getAuthor: async function(id) {
    //   const author = await this.api('users/' + id)
    //   return author['o:name']
    // },
    getParcours: async function(param) {
      const parcours = { fr: {parcours: [], categories: []}, en: { parcours: [], categories: []} }
      const sites = {
        fr: await this.api('sites', 'fr'),
        en: await this.api('sites', 'en'),
      }
      _.each(['fr', 'en'], lang => {
        _.each(sites[lang], p => {
          if(p['extended_site_is_hidden']) return

          const par = {
            id: p['o:slug'],
            title: p['o:title'],
            author: {
              parcours: p['extended_site_responsable_parcours'],
              editorial: p['extended_site_responsable_editorialisation']
            },
            in_visualisation: p['extended_site_in_home_visualisation'],
            visualisation_items: p['extended_site_visualisation_items'],
            description: p['o:summary'],
            introduction: p['o:summary'],
            cover: p['extended_site_description_image'] ? p['extended_site_description_image']['o:asset_url'] : null,
            introduction : p['extended_site_introduction'],
            categories : p['extended_site_description_categories'],
            is_linear : p['extended_site_description_linear'],
            chapters: [],
            slug_reverse: p['extended_site_slug_lang_reverse']
          }

          _.map(p['o:navigation'], page => {
            if(page.data.id) {
              if(par.chapters === null)
                par.chapters = []

              par.chapters.push(page.data.id)
            }
          })

          parcours[lang].parcours.push(par)
        })

        const categories = []
        _.each(parcours[lang].parcours, p => {
          _.each(p.categories, categorie => {
            if(categories.indexOf(categorie) === -1)
              categories.push(categorie)
          })
        })
        parcours[lang].categories = categories
      })

      return parcours
    },
    getChapters: async function(ids) {
      let count = 0
      let order = 0
      const chapters =  { introduction: [], visible: [], hidden: [] }
      while(count < ids.length) {
        const id = ids[count]
        const res = await this.api('site_pages/' + id)
        const medias = []
        _.each(res['o:block'], (block) => {
          if(block['o:layout'] == 'media') {
            _.each(block['o:attachment'], (attachment) => {
              if(attachment['o:item'] == null) return
              medias.push(attachment['o:media']['o:id'])
            })
          }
          if(block['o:layout'] == 'html') {
            medias.push({ type: 'html', data: block['o:data'] })
          }
        })

        let type = 'visible'

        if(res['o:slug'].search("__ci") > -1)
          type = 'hidden'

        if(res['o:slug'].search("__introduction") > -1)
          type = 'introduction'

        chapters[type].push({
          id : res['o:id'],
          slug: res['o:slug'],
          title : res['o:title'],
          order: order + 1,
          medias: medias
        })

        if(type == 'visible')
          order++

        count++
      }
      return chapters
    },
    getAsset: async function(id) {
      if(id.type != undefined)
        return this.parserMedias(id)

      const asset = await this.api('media/' + id)

      if(asset == 'error')
        return null

      return this.parserMedias(asset)
    },
    parserMedias(media) {
      let type = 'text'
      var options = {
        'add-remove-tags': ['span', 'table', 'tr', 'td'],
        'remove-attributes': ['style']
      }

      cleaner.clean(media.data.html, options,  html=> media.data.html = html )

      if(media.data.html.search('img') > 0) {
        media.data.html = _.replace(media.data.html, "<p>", "")
        media.data.html = _.replace(media.data.html, "</p>", "")
        type = 'image'
        media.src = media.data.html
      }

      if(media.data.html.search("iframe") > 0) {
        media.data.html = _.replace(media.data.html, "<p>", "<p class='is-video'>")
        type = 'video'
      }

      if(media.data.html.search("<p>&nbsp;</p>") > 0 )
        media.data.html = _.replace(media.data.html, "<p>&nbsp;</p>", "")

      if(media.data.html.search("<div>&nbsp;") > 0 )
        media.data.html = _.replace(media.data.html, "<div>&nbsp;", "<div>")

      let description = null
      if(media['dcterms:description'] && media['dcterms:description'].length > 0)
        description = media['dcterms:description'][0]['@value']

      let creator = null
      if(media['dcterms:creator'] && media['dcterms:creator'].length > 0)
        creator = media['dcterms:creator'][0]['@value']

      let title = null
      if(media['dcterms:title'] && media['dcterms:title'].length > 0)
        title = media['dcterms:title'][0]['@value']

      let ark = null
        if(media['dcterms:identifier'] && media['dcterms:identifier'].length > 0)
        ark = media['dcterms:identifier'][0]['@value']

      return {
        id: media['@id'],
        // url_public: _.replace(media['@id'], 'api/media', 'public/media'),
        url_public: this.$root.technes_url + '/' + this.lang + '/base/' + ark,
        title: title || null,
        description: description,
        creator: creator,
        type: type,
        html: media.data.html
      }
   }
  }
}
















